import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  submitForm(e) {
    e.preventDefault();
    let t = this;
    let data = new FormData(this.formTarget);

    Rails.ajax({
      type: "POST",
      url: e.target.action,
      data: data,
      dataType: "multipart/form-data",
      success: () => {
        Turbolinks.visit("/?demo_request_successful=1");
      },
      error: (response, xhr, status) => {
        console.log(response);
        if (response.email) {
          let emailField = t.formTarget.querySelector(
            'input[name="demorequest[email]'
          );
          emailField.classList.add("border-red-500");
          emailField.insertAdjacentHTML(
            "afterend",
            `<span style="text-align:left">${response.email.join(", ")}</span>`
          );
        }
        if (response.fullname) {
          let fullnameField = t.formTarget.querySelector(
            'input[name="demorequest[fullname]'
          );
          fullnameField.classList.add("border-red-500");
          fullnameField.insertAdjacentHTML(
            "afterend",
            `<span style="text-align:left">${response.fullname.join(
              ", "
            )}</span>`
          );
        }
        if (response.company) {
          let companyField = t.formTarget.querySelector(
            'input[name="demorequest[company]'
          );
          companyField.classList.add("border-red-500");
          companyField.insertAdjacentHTML(
            "afterend",
            `<span style="text-align:left">${response.company.join(
              ", "
            )}</span>`
          );
        }
        if (!response.email && !response.fullname && !response.company) {
          Turbolinks.visit("/?demo_request_failed=1");
        }
      },
    });
  }
}
