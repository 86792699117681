import heroicons from  './heroicons.json'
import React, { useState, useEffect } from "react";

const Svgx = (props) => {
 const icon = heroicons[props.svgKey]; 
 const view_box = {small: '0 0 20 20', medium: '0 0 24 24'}
 if (!icon){ 
   console.warn('SVG data not found for: ', props.svgKey); 
   return ""; 

 }
 
 const snakeToCamel = (str) => str.replace(/([-_]\w)/g, g => g[1].toUpperCase())

 let svgOpts = {...props.options,...icon.svg_attrs}
 if (!svgOpts['viewBox']) {
  svgOpts['viewBox'] = view_box[props.size]
 }
 svgOpts['xmlns'] =  "http://www.w3.org/2000/svg"

 const pathOpts =   Object.fromEntries(
   Object.entries(icon.path_attrs).map(([k,v]) => [snakeToCamel(k), v]) 
 )  
 return (
     
   <svg {...svgOpts}>
     <path {...pathOpts}> 

     </path>
   </svg>
    
 )

}
export default Svgx;
