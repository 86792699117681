import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["lockit"];

  connect() {
  }

  toggle(event) {
    let data = { room: { is_locked: this.lockitTarget.checked } };

    Rails.ajax({
      url: this.data.get("update-url"),
      type: "PATCH",
      dataType: "json",
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        options.data = JSON.stringify(data);
        return true;
      },
    });
  }
}
