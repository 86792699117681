import React from "react";

const MicOff = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="white"
    >
      <title>mic off</title>
      <g>
        <path
          className="cls-1"
          d="M124.43,58.21C121,43.59,106.57,34.42,92.22,37.78a27.21,27.21,0,0,0-21,26.45q.11,19.51,0,39a28.57,28.57,0,0,0,1.92,10.8L125,62.19A23,23,0,0,0,124.43,58.21Z"
        />
        <path
          className="cls-1"
          d="M65.12,104.26c0-2.29,0-4.58,0-6.87-.14-3.87-3.4-7.27-6.95-7a7,7,0,0,0-6.91,7.07c-.07,2.08,0,4.16,0,6.24a45,45,0,0,0,6.51,24c.22.37.44.72.67,1.08l10.14-10.15A32.06,32.06,0,0,1,65.12,104.26Z"
        />
        <path
          className="cls-1"
          d="M160.49,45.32A5.71,5.71,0,0,0,152,45L39,156.54a6.88,6.88,0,0,0-.34,9.2,5.76,5.76,0,0,0,4.18,2.07,5.86,5.86,0,0,0,4.33-1.75l24-23.73a47,47,0,0,0,6.36,3.75,49,49,0,0,0,11.75,4c1.65.33,2,1,1.92,2.48-.09,1.87,0,3.75,0,5.62h0c0,2.19-.12,4.38,0,6.56a6.91,6.91,0,0,0,8.22,6.1,7.18,7.18,0,0,0,5.61-7c.08-3.54,0-7.08,0-10.62,0-2.74,0-2.7,2.75-3.4a68.35,68.35,0,0,0,6.56-1.92c11.44-4.21,19.83-12,25.55-22.62,4.78-8.9,5.61-18.59,5.1-28.47-.23-4.39-5.59-8.22-10.13-5.53a7.15,7.15,0,0,0-3.75,6.39,110.34,110.34,0,0,1-.58,11.8c-2.4,17.49-19.89,30.23-38,27.12a32.41,32.41,0,0,1-11.28-4.25l4.48-4.42c7.19,4,15,4.35,23.14.68,10.62-4.81,16-13.44,16.16-25.2,0-4.77,0-9.53,0-14.3l35-34.59A6.89,6.89,0,0,0,160.49,45.32Z"
        />
      </g>
    </svg>
  );
};

export default MicOff;
