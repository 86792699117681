import { Controller } from 'stimulus'; 
import RemoteReactSelect from 'remote-react-select';
import React from 'react';
import ReactDom from 'react-dom';

class  UserCompanyEndpointResolver extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      baseEndpoint: this.props.baseEndpoint,
      companyId: this.props.companyId
    }
    this.fullEndpoint = this.fullEndpoint.bind(this);
  }

  fullEndpoint(){
    return this.state.baseEndpoint + '?company_id=' + this.state.companyId
  }

  onChange(inputValue, {action})  {
    switch(action) {
      case 'select-option':
        this.setState({companyId: inputValue.value})
        return;
    }
  }

  render() { 
    const endpoint = this.fullEndpoint() 
    return this.props.children(this.state.companyId, endpoint, this.onChange.bind(this))
  }
}
export default class extends Controller {
  static values = {
    endpoint: String, 
    options: Array, 
    defaultUser: Object,
    defaultCompany: Object,
    coPresenter: Boolean,
    formName: String, 
    errors: Object
  }




  connect() {
    let t = this;
    let userValue = ''
     if (t.defaultUserValue.value) {
        userValue = t.defaultUserValue
     }
    let formName = t.formNameValue || 'room_form'

    let companyFieldName = `${formName}[presenter_company]`
    let userFieldName = `${formName}[presenter_user]`
    if (this.coPresenterValue) {
      companyFieldName = `${formName}[co_presenter_company]`
      userFieldName = `${formName}[co_presenter_user]`
    }


    ReactDom.render( 
      <UserCompanyEndpointResolver baseEndpoint={t.endpointValue} companyId={t.defaultCompanyValue.value} > 
        {(companyId, endpoint, onChange) => {return (
          [
          <div key={0} className='flex items-center mb-5 whitespace-nowrap'> 
            <label className='w-1/2 h-12 mr-5 font-bold'>{this.coPresenterValue ? 'Co-' : ''} Presenter Company</label>
            <div className='w-full'>  
              <RemoteReactSelect name={companyFieldName} 
                defaultValue={t.defaultCompanyValue}
                placeholder= {'Select A Company'}
                onChange={onChange}
                className={`${t.errorsValue.presenter_company ? 'invalid border-2 rounded-sm' : ''}`}
                options={t.optionsValue}  />
              {t.errorsValue.presenter_company  &&  !this.coPresenterValue &&
                <p className='error'>{t.errorsValue.presenter_company.join(', ')}</p>
               }
            </div>
          </div>,
          <div key={1} className='flex items-center mb-5 whitespace-nowrap'> 
            <label className='w-1/2 h-12 mr-5 font-bold'>{this.coPresenterValue ? 'Co-' : ''}Presenter User</label>
            <div className='w-full'>  
              <RemoteReactSelect  name={userFieldName} 
                defaultValue={userValue} 
                placeholder={'Select a User'}
                className={`${t.errorsValue.presenter_user ? 'invalid border-2 rounded-sm' : ''}`}
                endpoint={endpoint} />
                {t.errorsValue.presenter_user  && !this.coPresenterValue && 
                <p className='error'>{t.errorsValue.presenter_user.join(', ')}</p>
               }
            </div>
          </div>
          ]
        )
        }}
      </UserCompanyEndpointResolver>,
        this.element) 
      }
   }

