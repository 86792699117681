import React from "react";
import ApiContext from './api_context.js'

export default class AwayMessageToggle extends React.Component {
  static contextType = ApiContext
  constructor(props) {
    super(props);
    this.state = { isToggleOn: this.props.roomIsLocked};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let t = this;
    let data = {room: {is_locked: !this.props.roomIsLocked}}
    this.context.toggleRoomLock(data, () => {
    // t.setState((prevState) => ({
    //   isToggleOn: !prevState.isToggleOn
    // }))
    },
      (error) => {
      try  {
        alert(error.errors.join(','))
      }
      catch {
        alert('There was a problem locking this room')
      }
     }
    )
  }

  render() {
    return (
      <div className="mt-16">
        <div className="flex flex-row items-center">
          <button onClick={this.handleClick} className="flex flex-row">
            {this.props.roomIsLocked ? (
              <div className="flex flex-row">
                <div className="bg-white border border-gray-light font-bold py-2 px-5 text-center rounded-l-2xl text-xs">
                  OFF
                </div>
                <div className="bg-gray-dark border border-gray-light text-white font-bold py-2 px-5 text-center rounded-r-2xl text-xs">
                  ON
                </div>
              </div>
            ) : (
              <div className="flex flex-row">
                <div className="bg-gray-dark border border-gray-light font-bold py-2 px-5 text-white text-center rounded-l-2xl text-xs">
                  OFF
                </div>
                <div className="bg-white border border-gray-light font-bold py-2 px-5 text-center rounded-r-2xl text-xs">
                  ON
                </div>
              </div>
            )}
          </button>
          <div className="ml-2 text-sm">Away Message</div>
        </div>
        <div className="text-xs mt-2">
          {this.props.roomIsLocked
            ? "New attendees are currently not able to join your room."
            : ""}
        </div>
      </div>
    );
  }
}
