import React from "react";
import ahoy from "ahoy.js";
export default class Handouts extends React.Component {
  download(e, attachment) {
    ahoy.track(`Downloaded Handout: ${attachment.name}`, {
      attachment_id: attachment.id,
    });
  }

  render() {
    let t = this;
    return (
      <div className="my-1 px-1 w-full">
        <h3 className="font-bold text-lg my-3">Handouts</h3>
        {this.props.attachments.map(function (attachment, i) {
          return (
            <a
              key={i}
              href={attachment.url}
              onClick={(e) => t.download(e, attachment)}
              target="_blank"
              className="font-bold text-base pl-4 py-4 rounded shadow-lg block w-80 border border-gray-light my-2 text-left"
            >
              <img
                src="/PDF_32.png"
                className="w-6
      inline-block mr-2"
              ></img>
              {attachment.name}
            </a>
          );
        })}
      </div>
    );
  }
}
