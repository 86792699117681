import React from 'react'; 
import Chat from './event_rooms/chat.js'; 
import Loader from './loading_animation.js'; 
import consumer from './channels/consumer.js'; 
import Rails from "@rails/ujs";
import { messageReceived, sendMessage } from './shared/chat_shared.js'; 
export default class AgentAttendeeChat extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      loading: true, 
      messages: []
    }
    this.subscribeChannel = this.subscribeChannel.bind(this);
  }

  fetchMessages(success, error){
    let  t = this;
    Rails.ajax({
      type: 'GET', 
      url: t.props.messagesUrl, 
      dataType: 'json',
      success: success, 
      error: error 
    }
    )
  }

  subscribeChannel(){
    let  context = this;
    return consumer.subscriptions.create({channel: 'AgentAttendeeChatChannel',
      user: context.props.user, company: context.props.company}, {
        connected: function() {
        }, 
        received: function(data) {
          messageReceived(context, data); 
          if (data.message.user.id !== context.props.currentUser  && (typeof context.props.notifyOfMessages === 'function')) { 
           context.props.notifyOfMessages(); 
          }
        }
      }) 
  } 

  componentDidMount(){ 
    let  t = this;
    this.fetchMessages(function(data) { 
      t.consumer = t.props.consumer ? t.props.consumer :  t.subscribeChannel(); 

      t.setState({messages: data, loading: false})
    }, function(errors) {
      alert(errors); 
    })
  }

  componentWillUnmount(){
    if (this.consumer) {
      this.consumer.unsubscribe(); 
    }

  }

  render() {
    return (
      <div> 
        {this.state.loading && 
        <Loader elementOriented={true} /> 
        }
        {!this.state.loading &&
          <Chat messages={this.state.messages}
          chatType={'support'}
          currentUser={this.props.currentUser}
          sendMessage={sendMessage.bind(this)} />
        }
      </div>
    ); 
  }
}
