import React from "react";
import Rails from "@rails/ujs";
import Svgx from "../shared/svgx.js";
export default class RequestInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accreditationRequested: this.props.accreditationRequested,
      aiaNumber: this.props.aia_number
    };
    this.toggleAccreditationRequested = this.toggleAccreditationRequested.bind(
      this
    );
    this.accreditationRequest = this.accreditationRequest.bind(this);
  }

  toggleAccreditationRequested() {
    let t = this;
    this.accreditationRequest(
      (data) => {
        t.setState({
          accreditationRequested: !this.state.accreditationRequested,
        });
      },
      (errors) => {
        try {
          alert(errors.errors.join(", "));
        } catch {
          alert("There was a problem completing your request");
        }
      }
    );
  }

  handleChange(e) {
    let name = e.target.name;
    this.setState((prevState) => ({...prevState, [name]: e.target.value}) )

  }

  accreditationRequest(success, error) {
    let t = this;
    const url = `/rooms/${this.props.room_id}/accreditation_requests`;
    let data = {accreditation_request: {aia_number: this.state.aiaNumber}}
    Rails.ajax({
      type: this.state.accreditationRequested ? "DELETE" : "POST",
      url: url,
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        options.data = JSON.stringify(data);
        return true;
      },
      success: success,
      error: error,
    });
  }

  render() {
    const btnClass = this.state.accreditationRequested
      ? "bg-apricot-light"
      : "border-gray-300 border-2";
    const iconClass = this.state.accreditationRequested
      ? "text-white"
      : "text-gray-300";

    const messagingTextCredits = this.state.accreditationRequested
      ? "Great! The presenter will follow up with you after the event."
      : "Would you like to request credit for this course?";

    const messagingTextNoCredits = this.state.accreditationRequested
      ? "Thank you. The presenter will follow up with you after the event."
      : "I'd like to request more information from this presenter.";

    const socialLinks =
      this.props.presenter &&
      this.props.presenter.company &&
      this.props.presenter.company.social_links;

    return (
      <div>
        <div className="p-5 rounded-xl shadow-lg block w-full border border-gray-light my-2 text-left">
          {this.props.presenter && this.props.presenter.company && this.props.presenter.company.logo_data &&
          <img
            src={this.props.presenter.company.logo_data.thumb}
            className="w-32 inline-block"
          ></img>
          }
          <div className="inline-block inline-flex flex-row py-5 px-9">
            {socialLinks &&
              Object.keys(socialLinks).map((d, i) => {
                return (
                  <a
                    key={i}
                    href={socialLinks[d]}
                    target="_blank"
                    className="inline-block text-white align-middle relative text-white h-8 w-8 rounded-full mr-2 bg-gray-light"
                  >
                    <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-sm">
                      <Svgx
                        svgKey={d}
                        options={{ className: "small", width: "15px" }}
                        size={"small"}
                      />
                    </div>
                  </a>
                );
              })}
          </div>
          <div className="my-3">
            <p className="text-sm">
              {this.props.presenter &&
                this.props.presenter.company &&
                this.props.presenter.company.description}
            </p>
          </div>
          <div className={`flex flex-row  text-sm items-center my-7 ml-3 ${( this.props.ceu_credits && !this.state.accreditationRequested ) ? " flex-wrap" : ""} `}  >
            {!this.props.ceu_credits &&
            <button
              onClick={this.toggleAccreditationRequested}
              className={`rounded-full flex items-center justify-center py-1 ${btnClass}  w-14`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className={`inline-block ${iconClass} w-5`}
              >
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
              </svg>
            </button>
            }

            {this.props.ceu_credits && (
              <>
              <p className="ml-2 italic mr-5">{messagingTextCredits}</p>
              </>
            )}
            {this.props.ceu_credits && (
              <>
              {!this.state.accreditationRequested &&
             <div className='w-full flex flex-row mt-5'> 
               <input className='form-field'  
                 placeholder="Enter AIA Number"
                 onChange={this.handleChange.bind(this)}
               type='text' name={'aiaNumber'} value={this.state.aiaNumber}  />
               <button className='btn h-11 rounded-xl bg-gradient-to-r from-apricot-dark to-apricot-light  ml-5'  
               onClick={this.toggleAccreditationRequested}>Submit</button>
             </div>
              }

             {this.state.accreditationRequested &&
             <button
              onClick={this.toggleAccreditationRequested}
              className={`rounded-full flex items-center justify-center py-1 ${btnClass}  w-14`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className={`inline-block ${iconClass} w-5`}
              >
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
              </svg>
            </button>

              }
              </>
            )

               
            }
            {!this.props.ceu_credits && (
              <p className="inline-block ml-2 italic">
                {messagingTextNoCredits}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
