import React, { useState, useEffect } from "react";
import MicOff from "./mic_off";
import MicOn from "./mic_on";
import CameraOn from "./camera_on";
import CameraOff from "./camera_off";
import AudioViz from "./audio_viz.js"
import MicCameraToggling from '../shared/mic_camera_toggling.js'
import Svgx from '../shared/svgx.js';
import deviceFilter from '../shared/device_filter.js'
import cameraStates from './utils/camera_states.js'
import useCameraStatus from './hooks/use_camera_status.js'
const EntryControls = (props) => {
  return MicCameraToggling(props, (args) => { 
   const {handleCameraClick, handleAudioClick} = args
   const audio_toggle = props.micEnabled
   const cameraState = useCameraStatus(props)

   const cameraAvailable =  ( ) => {
      return deviceFilter(props.videoInputDevices)
         }
   const micAvailable =  ( ) => {
      return  deviceFilter(props.audioInputDevices)
   }

   const speakerAvailable =  ( ) => {
      return  deviceFilter(props.audioOutputDevices)
   }

   let cameraClass = cameraState === cameraStates.active
                ? "inline-block align-middle relative text-white h-9 w-9 rounded-full ml-2 bg-green mr-2 mt-2"
                : "inline-block align-middle relative text-white h-9 w-9 rounded-full ml-2 bg-red-600 mr-2 mt-2"
    return (
    <div className="mt-6">
      <div className="flex flex-col border-2 border-gray-light rounded-lg shadow-md px-4 py-6">
        <div className="flex flex-row my-5">
          <button
            onClick={handleCameraClick}
            className={`${cameraClass} ${cameraAvailable() ? '' : 'cursor-not-allowed disabled:opacity-50'}`}
            disabled={cameraAvailable() ? false : true }
          >
            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
              {cameraState === cameraStates.active ? <CameraOn /> : <CameraOff />}
            </div>
          </button>
          <div className="flex flex-col w-5/6">
            {cameraAvailable() &&  (
            <>
            <h3 className="font-semibold ml-1">Select Camera</h3>
              <select
                name="VideoInput"
                className="bg-gray-100 text-gray-dark p-1 text-sm"
                onChange={props.changeDevice}
              >
                {props.videoInputDevices.map((d, i) => {
                  return (
                    <option value={d.deviceId} key={d.deviceId}>
                      {d.label}
                    </option>
                  );
                })}
              </select>
            </>
            )}
            {!cameraAvailable() && (
              <>
             <h3 className="font-semibold ml-1">Camera is unavailable</h3>
                <div className='ml-2'> 
                  <small>Check your device permissions if you have a camera that should be connected.
                 </small>
             </div>
             </>
            )
            }
          </div>
        </div>
        <div className="flex flex-row my-5">
          <button
            onClick={handleAudioClick}
            className={
              audio_toggle
                ? "inline-block align-middle relative text-white w-9 h-9 rounded-full ml-2 bg-green mr-2 mt-2"
                : "inline-block align-middle relative text-white w-9 h-9 rounded-full ml-2 bg-red-600 mr-2 mt-2"
            }>
            <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
              {audio_toggle ? <MicOn /> : <MicOff />}
            </div>
          </button>
         {micAvailable() &&  (
          <div className="flex flex-col w-5/6">
            <h3 className="font-semibold ml-1">Select Microphone</h3>
            <select name="AudioInput"
                role='jst-audio-input-selection'
                className="bg-gray-100 text-gray-dark p-1 text-sm"
                onChange={props.changeDevice}
              >
                {props.audioInputDevices.map((d, i) => {
                  return (
                    <option value={d.deviceId} key={d.deviceId || i}>
                      {d.label}
                    </option>
                  );
                })}
              </select>
          </div>
            )}
        </div>
        <div className="flex flex-row my-5">
          <div className="inline-block align-middle relative text-white h-9 w-9 rounded-full ml-2 mr-2 mt-2">
            <div  className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full text-gray-light">
            <Svgx
               svgKey={"volume-up"}
               options={{ className: "inline" }}
               size={"small"} />
            </div>
          </div>
          <div className="flex flex-col w-5/6">
            {speakerAvailable()  && (
            <>
            <h3 className="text-lg font-semibold ml-1">Select Speakers</h3>
              <select
                name="AudioOutput"
                className="bg-gray-100 text-gray-dark p-1 text-sm"
                onChange={props.changeDevice}
              >
                {props.audioOutputDevices.map((d, i) => {
                  return (
                    <option value={d.deviceId} key={d.deviceId}>
                      {d.label}
                    </option>
                  );
                })}
              </select>
            </>
             )}           
            <div className='ml-1'> <button className='text-blue-light font-medium text-sm' 
            onClick={props.playTestAudio}>Click To Test Your Speakers</button> </div>
          </div>
        </div>
        <div className="flex flex-row items-center mx-auto">
          <p className="mr-6">Speak to test your microphone...</p>
          {props.analyzeNode && (
            <div>
              <AudioViz analyser={props.analyzeNode} />
            </div>
          )}
        </div>
      </div>
    </div>
    )
   }
  );
};

export default EntryControls;
