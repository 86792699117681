import React, {useContext, useState}  from 'react';
import SessionManagerContext from './session_manager_context.js'; 
import VideoTile from './video_tile.js'; 

const  AgencySupport = (props) => {
  const chime = useContext(SessionManagerContext);
  const [visibleIndices, setVisibleIndices] = useState({}) 

  React.useEffect(() => {
      const callback = (roster, role) => {
        let ind = {}
         chime.sessionManager.tileManager.supportTiles().forEach((value, index) => {
          if (value.user_id ){
            ind[index] = {attendeeId:  value.attendeeId}
          }
          else {
            ind[index] = null
          }
         })
         setVisibleIndices((previousVisibleIndices) => ({
           ...previousVisibleIndices, ...ind
         })
         )
        }
      chime.sessionManager.subscribeToRosterUpdate(callback); 
       return () => {
        chime.sessionManager.unsubscribeFromRosterUpdate(callback); 
      }
    }, [])
  const numVisibleIndices = Object.keys(visibleIndices).reduce((result, key) => {
      return result + (visibleIndices[key] ? 1 : 0)
      }, 0
     );
    return (
     <>
      {numVisibleIndices > 0 && (
                <div className="px-2 py-1 bg-gradient-to-r from-apricot-dark to-apricot-light mb-3">
                  <h2 className="px-1 py-1 text-lg text-white font-semibold">
                    AGENCY SUPPORT
                  </h2>
                </div>
        )}
       <div className={`grid grid-cols-2 gap-2 ${numVisibleIndices > 0 ? 'mb-5' : '' }`}>
        {Array.from(Array(chime.sessionManager.tileManager.supportTiles().length).keys()).map((key, index) => {
         const visibleIndex = visibleIndices[index]
         const tileData = chime.sessionManager.tileManager.supportTiles()[index]
         const attendeeId = visibleIndex ? visibleIndex.attendeeId : null
         const user = chime.sessionManager.tileManager.tileIdToUserMap[attendeeId]
            return ( 
            <VideoTile
              key={index}
              tileData={tileData} 
              showVideoStatus={true}
              user={user} />

           )
          })
        }
      </div>
      </>
    )
  }

export default AgencySupport;

