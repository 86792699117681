import React, { useState, useEffect, useContext } from "react";
import ApiContext from '../api_context.js'; 
import SessionManagerContext from '../session_manager_context.js'; 

const useCanUnmute = (initial, user) => {
  const api = useContext(ApiContext)
  const chime = useContext(SessionManagerContext)
  const [canUnmute, setCanUnmute] = useState(initial)
  useEffect(() => {
    const _callback = (data) => {
      let unmute = !data 

      if (user.role !== 'presenter') {
        chime.sessionManager.meetingSession.audioVideo.realtimeSetCanUnmuteLocalAudio(unmute)
        if (!unmute) {
          chime.sessionManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
        }
       setCanUnmute(unmute) 
      }
      else {
       setCanUnmute(unmute)
      }
    }
    
   api.subscribeToMuteToggleUpdates(_callback)
    return () => {
      api.unsubscribeFromMuteToggleUpdates(_callback)
    }
   }, [])
   
    return canUnmute
}

export default useCanUnmute
