import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleable"];
  static values = { toggleClass: String };
   
  connect() {
    this.element[this.identifier] = this
  }

  toggle(e) {
    e.preventDefault();
    this.toggleableTarget.classList.toggle(this.toggleClassValue);
  }
}
