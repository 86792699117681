import React from "react";
import ConferencingSession from "../shared/conferencing";
import SessionObserver from "./observer.js";
import Loader from "../loading_animation.js";
import Svgx from "../shared/svgx.js";
import ahoy from "ahoy.js";
import TileManager from "./tile_manager.js";
import SessionManagerContext from './session_manager_context.js'; 
import LeftColumn from './layout/left_column.js'; 
import MiddleColumn from './layout/middle_column.js'; 
import RightColumn from './layout/right_column.js'; 
import Status from './status.js'; 
import InfoBar from './info_bar.js';

export default class EventRoomMain extends React.Component {
  constructor(props) {
    super(props);
    this.audioRef = React.createRef();
    this.screenShareRef = React.createRef(); 
    this.tileManager = new TileManager();

    this.state = {
      loading: true,
      screenShareOn: false,
      canUnmute: true,
      canTurnCameraOn: true,
      roomLockOn: false,
    };
    this.findUser = this.findUser.bind(this);
    this.sessionCleanUp = this.sessionCleanUp.bind(this);
    this.userLeft = this.userLeft.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
  }

  componentDidMount() {
    let t = this;
    this.sessionManager = this.props.sessionManager;
    this.sessionManager.buildObserver(SessionObserver(t));
    this.sessionManager.addTileManager(this.tileManager); 
    this.tileManager.addGroups("attendee", "presenter", "agent");
    this.sessionManager.videoTogglePref = this.props.startsWithVideo
    window.addEventListener("beforeunload", this.userLeft);
  }

  componentDidUpdate(prevProps) {
    let t = this;
    if (!this.props.session_data) return "";

    if (this.state.loading) {
      this.sessionManager
        .setSession(
          this.props.session_data.meeting_response,
          this.props.session_data.attendee_response
        )
        .then(function () {
          t.setState({ loading: false });
        });
    }
    if (
      !this.state.ready &&
      !this.state.loading &&
      !this.state.ran_once
    ) {
      this.sessionManager.bindAudioAndVideo(this.audioRef.current, this);
    }
  }

  componentWillUnmount() {
    this.sessionCleanUp();
    document.removeEventListener("beforeunload", this.userLeft);
    this.sessionManager.unsubscribeFromRosterUpdate(this.callback); 
  }

  sessionCleanUp() {
    if (this.sessionManager) {
      this.sessionManager.endUserSession();
      this.userLeft();
    }
  }

  toggleMute(val) {
    this.setState({ muteToggleOn: val });
  }

  userLeft() {
    ahoy.track("User Left Room", { room_id: parseInt(this.props.room_id, 10) });
  }

  findUser(id) {
    return this.props.attendees.filter(function (d, i) {
      return d.id.toString() === id.toString();
    })[0];
  }

  render() {
    let t = this;
    return (
      <SessionManagerContext.Provider value={{sessionManager: this.sessionManager}}>
       <div className="min-h-screen">
        {(this.state.loading ) &&  <Loader testid='jst-event-room-loader-1' />}
        {(!this.state.loading && !this.state.ready) &&  <Loader text={'We Are Connecting You to the Room...'} />}

          <div className="mx-auto py-2 sm:px-6 lg:px-8 px-2">
          <audio ref={this.audioRef}> </audio>
          <InfoBar {...this.props} /> 
          <div className="flex flex-col lg:flex-row">
            <LeftColumn
              {...this.state}
              {...this.props}
              toggleMute={this.toggleMute}
              findUser={this.findUser}

            />
            <MiddleColumn 
             {...this.state}
              {...this.props}
              toggleMute={this.toggleMute}
              findUser={this.findUser}
              screenShareRef={this.screenShareRef}
            />
            <RightColumn
              {...this.state}
              {...this.props}
              toggleMute={this.toggleMute}
              findUser={this.findUser}
              tileManager={this.tileManager}
            />
          </div>
        </div>
         {this.state.ready &&
         <Status wrapperClass='w-full bg-gray-800 p-3 fixed h-12 text-white text-md bottom-0' />
         }
      </div>
      </SessionManagerContext.Provider>
    );
  }
}
