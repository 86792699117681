import React from "react";
import ReactDOM from "react-dom";
import consumer from '../../channels/consumer.js'
import { Controller } from 'stimulus'; 
import Rails from "@rails/ujs";
import  AgentAttendeeChat  from '../../agent_attendee_chat.js'; 

export default class extends Controller {
  static targets = ['chatbox', 'content', 'counter', 'audio']
  static values = {
    mountOnConnect: Boolean, 
    messageCount: Number 
  }

  connect() {
    this.element[this.identifier] = this; 
    if (this.hasAudioTarget) {
      this.audioTarget.load()
    }
    if (this.mountOnConnectValue){
      this.mountChat(); 
    }
  }

  disconnect(){
   if (!this.chatboxTarget.classList.contains('hidden')) {
    this.connect()
   }
   

  }

  countMessage( ){
    try {
      this.messageCountValue += 1
      this.counterTarget.innerHTML = this.messageCountValue;
      if (this.chatboxTarget.classList.contains('hidden')){
        this.counterTarget.classList.remove('hidden'); 
        if  (this.hasAudioTarget){
          this.audioTarget.play()
        }
      }
      else {
        this.resetMessageCounter(); 
      }
    }
    catch(e) {
      console.warn('message counter not present', e); 

    }
  }

  resetMessageCounter(){
    let beforeCount = 0
    try {
      beforeCount = this.messageCountValue; 
      this.messageCountValue = 0; 
      this.counterTarget.innerHTML = this.messageCountValue; 
      this.counterTarget.classList.add('hidden'); 
    }

    catch(e) {
      console.warn('message counter not present', e)

    }
    
    //update the server so read messages are persisted, 
    //since agents reload and mark messages as read every 
    //time they open the chat, we only need to do this 
    //for attendees who have a persistent chat connection
    if (this.mountOnConnectValue) {
      let  t = this;
      let url =   t.data.get('messagesUrl').replace('messages', 'read_messages') 
      url = url  + `?unread_count=${beforeCount}`
      Rails.ajax({
        type: 'PUT', 
        url: url, 
        dataType: 'json',
        success: function(){ },
        error: function(error){ console.log(error);  }
      })
    }
  }

  mountChat(){
    ReactDOM.render(<AgentAttendeeChat 
      user={this.data.get('user')} 
      currentUser={this.data.get('currentUser')}
      messagesUrl={this.data.get('messagesUrl')} 
      notifyOfMessages={this.countMessage.bind(this)}
      company={this.data.get('company')} />, 
      this.contentTarget)
    this.chatMounted = true; 
  }

  close(e) {
    if (!this.mountOnConnectValue) { 
      ReactDOM.unmountComponentAtNode(this.contentTarget);
      this.chatMounted = false; 
    }
    this.chatboxTarget.classList.add('hidden')
    this.chatLink.classList.remove('bg-apricot-dark')
    this.chatLink.classList.remove('hover:bg-apricot-dark')
    this.chatLink.classList.remove('text-white')
    this.chatLink.classList.add('text-gray-300')
  }

  get chatLink() {
    return this.element.querySelector('[data-action="click->event-management--attendee-chat#show"]'); 

  }

  show(e){
    e.preventDefault(); 
    if (this.chatboxTarget.classList.contains('hidden')){
      let attendeeTableController = this.element.closest('[data-controller*="event-management--attendees"]')
      if (attendeeTableController && attendeeTableController['event-management--attendees']){
        try {
        attendeeTableController['event-management--attendees'].closeOtherChats()
        }
        catch(e) {
          console.warn('some other chats could not be closed', e)

        }  
      }
      this.chatboxTarget.classList.remove('hidden')

      this.resetMessageCounter();
      if (!this.chatMounted){
        this.mountChat();
      }
      this.chatLink.classList.add('bg-apricot-dark')
      this.chatLink.classList.add('hover:bg-apricot-dark')
      this.chatLink.classList.add('text-white')
      this.chatLink.classList.remove('text-gray-300')


    }
    else {
      this.close(e);   


    }
  }
}
