import React, {useContext, useState}  from 'react';
import SessionManagerContext from './session_manager_context.js'; 
import VideoTile from './video_tile.js'; 

const  PresenterVideo = (props) => {
  const chime = useContext(SessionManagerContext);
  const [visibleIndices, setVisibleIndices] = useState({}) 
  React.useEffect(() => {
      const callback = (roster, role) => {
        let ind = {}
         chime.sessionManager.tileManager.presenterTiles().forEach((value, index) => {
          if (value.user_id ){
            ind[index] = {attendeeId:  value.attendeeId}
          }
          else {
            ind[index] = null
          }
         })
         setVisibleIndices((previousVisibleIndices) => ({
           ...previousVisibleIndices, ...ind
         })
         )
        }
      chime.sessionManager.subscribeToRosterUpdate(callback); 
       return () => {
        chime.sessionManager.unsubscribeFromRosterUpdate(callback); 
      }
    }, []) 

   const numVisibleIndices = Object.keys(visibleIndices).reduce((result, key) => {
      return result + (visibleIndices[key] ? 1 : 0)
      }, 0
     );
  return (
    <div className={`grid gap-2 grid-cols-${numVisibleIndices}`}
       >
     {Array.from(Array(chime.sessionManager.tileManager.presenterTiles().length).keys()).map((key, index) => {
         const visibleIndex = visibleIndices[index]
         const tileData = chime.sessionManager.tileManager.presenterTiles()[index]
         const attendeeId = visibleIndex ? visibleIndex.attendeeId : null
         const user = chime.sessionManager.tileManager.tileIdToUserMap[attendeeId]
       return (
       <div key={index} className="my-1 px-1 w-full">
        <article>
          <div className="bg-black">
            <VideoTile
              tileData={tileData}
              user={user}
              hideNameTag={true}
              showVideoStatus={true}
              background={""}
            />
          </div>
        </article>
      </div>
       )
      })
      }
    </div>
    );
  }
export default PresenterVideo
