import React, {useContext, useState} from "react";
import SessionManagerContext from './session_manager_context.js'; 
import Svgx from "../shared/svgx.js";

const  VolumeIndicator  = (props) => {
const [isMuted, setMuted] = useState(""); 
const chime = useContext(SessionManagerContext);
   React.useEffect(() => {
      let previousMuted = null
      const callback = (roster) => {
      const attendee = roster[props.attendeeId]  
        if (attendee) {
          if (previousMuted !== attendee.muted) 
          {
           setMuted(attendee.muted); 
          }
        }
        previousMuted = attendee && attendee.muted
      }
     if (chime.sessionManager) {
       chime.sessionManager.subscribeToRosterUpdate(callback); 
     }
       return () => {
        if (chime.sessionManager) {
        chime.sessionManager.unsubscribeFromRosterUpdate(callback); 
        }
      }
    }, [])


     const indicator = isMuted ? (
      <Svgx
        svgKey={"volume-off"}
        options={{ className: "inline" }}
        size={"small"}
      />
    ) : (
      <Svgx
        svgKey={"volume-up"}
        options={{ className: "inline" }}
        size={"small"}
      />
    );
    return (
      <div className={props.className}>
        {isMuted !== "" && indicator}
      </div>
    );
}

export default VolumeIndicator; 
