import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["tab", 'navitem'];
  static values = { tabToggleClass: String, 
                    navActiveToggleClass: String, 
                    navInactiveToggleClass: String};
   
  connect() {
  }

  removeMultipleClasses(str, targ) {
    str.split(' ').forEach((token) => {
      targ.classList.remove(token)
    })
  }

   addMultipleClasses(str, targ) {
    str.split(' ').forEach((token) => {
      targ.classList.add(token)
    })
  }

   switchTab(e) {
    e.preventDefault();
    let t = this;
    this.addMultipleClasses(this.navActiveToggleClassValue, e.target)
    this.removeMultipleClasses(this.navInactiveToggleClassValue, e.target)

    this.navitemTargets.forEach((v, i) => {
      if (v !== e.target) {
       this.removeMultipleClasses(this.navActiveToggleClassValue, v)
       this.addMultipleClasses(this.navInactiveToggleClassValue, v)

      }
    })

    this.tabTargets.forEach((v, i) => {
      if (v.dataset.tabToggleId === e.target.dataset.tabToggleId ){
        this.removeMultipleClasses(this.tabToggleClassValue, v)
      }
      else {
        this.addMultipleClasses(this.tabToggleClassValue, v)
      }
   })
  }
}
