import React from 'react'; 
import MuteAllToggle from "./mute_all_toggle";
import AwayMessageToggle from "./away_message_toggle";
const RoomControls = (props) => {

  return (
    <>
      <h3 className="text-lg uppercase font-bold mb-3 mt-5">Room Controls</h3>
      <div>
        <MuteAllToggle   sessionManager={props.sessionManager} />
      </div>
      <div>
        <AwayMessageToggle roomIsLocked={props.roomIsLocked}   />
      </div>
    </>
  )


}

export default RoomControls;
