import React from 'react'; 
//adapted from https://www.twilio.com/blog/audio-visualisation-web-audio-api--react
const FPS = 30 
export default class AudioViz extends React.Component {

  constructor(props) {
    super(props);
    this.state = { audioData: new Uint8Array(0) };
    this.tick = this.tick.bind(this);
    this.dataArray = new Uint8Array(this.props.analyser.frequencyBinCount);
    this.canvas = React.createRef(); 
    
  }

  componentDidMount(){
    this.dataArray = new Uint8Array(this.props.analyser.frequencyBinCount);
    this.rafId = requestAnimationFrame(this.tick);
  }

  componentWillUnmount(){
    cancelAnimationFrame(this.rafId);
    if (this.timeout) {
      clearTimeout(this.timeout); 
    }
  }

  tick(){
    let t = this;
    this.timeout = setTimeout(()=> {
    t.props.analyser.getByteTimeDomainData(this.dataArray);
    t.setState({ audioData: this.dataArray });
    t.rafId = requestAnimationFrame(this.tick);
    }, 1000/FPS)
  }

  draw(){
    const audioData = this.state.audioData;
    const canvas = this.canvas.current;
    const height = canvas.height;
    const width = canvas.width;
    const context = canvas.getContext('2d');
    let x = 0;
    const sliceWidth = (width * 1.0) / audioData.length;
    context.lineWidth = 2;
    context.strokeStyle = '#ee9521';
    context.clearRect(0, 0, width, height);
    context.beginPath();
    context.moveTo(0, height / 2);
    for (const item of audioData) {
       let y = 0;  
      //flatten it a bit so its less noisy
      if (Math.abs(127 - item) < 10){
        y = (127 / 255.0) * height; 
      }
      else {
        y = (item / 255.0) * height;
      }
      context.lineTo(x, y);
      x += sliceWidth;
    }
    context.lineTo(x, height / 2);
    context.stroke();
  }

  componentDidUpdate(){
    this.draw();

  }

  render() {
    return  (<canvas style={{display: 'inline'}} width='50' height='50' ref={this.canvas} />    )
  }

}

