//classes prepended with jst are strictly for automated testing -- not for styling
//accordingly they shouldn't be changed or be used for hanging styles off of
import React, { useState, useEffect, useContext, useRef } from "react";
import VolumeIndicator from "./volume_indicator";
import CameraOn from './camera_on.js'; 
import CameraOff from './camera_off.js'; 
import Avatar from './avatar.js'; 
const VideoTile =  (props) => {
const vidTile =  useRef(null);
  React.useEffect(() => { 
    if (props.tileData) {
      props.tileData.tile = vidTile
    }
  })
  let baseBg =
    props.background || " relative border-solid border border-light-blue-500";
  if (props.user) {
    baseBg += " embed-responsive aspect-ratio-16/9 ";
  }
  return (
    <div className={props.user ? baseBg + " bg-black" : baseBg + " hidden"}>
       <video className='jst-video-tile' ref={vidTile}> </video>
      <div
        className={`text-white absolute bottom-0 w-full ${
            props.user && !props.hideNameTag ? "bg-black" : ""
          }  
          px-2 bg-opacity-70 text-xxs xl:text-xs text-left flex items-center`}
      >
        {props.user && !props.hideNameTag && (
          <span data-testid='jst-video-nametag' className="text-xxs xl:text-xs mr-3 inline-block whitespace-nowrap overflow-hidden max-w-sm">{props.user.name}</span>
        )}
        {props.tileData && props.tileData.attendeeId &&
        <VolumeIndicator
          className="w-2.5 md:w-3 xl:w-3.5 pb-0.5 md:pb-0"
          attendeeId={props.tileData.attendeeId} 
        />
        }
        {props.showVideoStatus && props.user && 
          <div className='w-3 md:w-4 xl:w-5' style={{paddingTop: '.22rem'}}>
            {(props.user.video_active ? <CameraOn /> : <CameraOff />)}
          </div>
        }
      </div>
      {props.showVideoStatus && props.user && !props.user.video_active &&
      <div className='absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
        <Avatar 
          name={props.user.name}
          sizingClasses={'h-16 w-16'}
          avatarColor={'#f16b36'}
          textSize={'text-xl'}
         />
        </div>
      }
      {props.children}
    </div>
  );
}

export default VideoTile;
