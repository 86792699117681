import React, {useState, useContext}  from 'react';
import SessionManagerContext from './session_manager_context.js'; 
import VideoTile from './video_tile.js';
const  Attendees = (props) => {
  const chime = useContext(SessionManagerContext);
  const [visibleIndices, setVisibleIndices] = useState({}) 
  React.useEffect(() => {
      const callback = (roster, role) => {
         let ind = {}
         chime.sessionManager.tileManager.attendeeTiles().forEach((value, index) => {
          if (value.user_id ){
            ind[index] = {attendeeId:  value.attendeeId}
          }
          else {
            ind[index] = null
          }
         })
         setVisibleIndices((previousVisibleIndices) => ({
           ...previousVisibleIndices, ...ind
         })
         )
       }
     chime.sessionManager.subscribeToRosterUpdate(callback); 
      return () => {
        chime.sessionManager.unsubscribeFromRosterUpdate(callback); 
      }
    }, [])

    const baseBg = 'relative text-center text-white' 
    return (
      <div className={props.wrapperClass}>
      <div className="grid grid-cols-3 gap-2 mt-2">
        {Array.from(Array(chime.sessionManager.tileManager.attendeeTiles().length).keys()).map((key, index) => {
         const visibleIndex = visibleIndices[index]
         const tileData = chime.sessionManager.tileManager.attendeeTiles()[index]
         const attendeeId = visibleIndex ? visibleIndex.attendeeId : null
         const user = chime.sessionManager.roster()[attendeeId]
         return ( <VideoTile 
                    key={index}
                    user={user}
                    tileData={tileData} 
                    background={baseBg} 
                    showVideoStatus={true}
                   />)
          })
        }
      </div>
     </div>
    )
} 
export default Attendees
