import React, { useState, useEffect, useContext } from "react";
import SessionManagerContext from '../session_manager_context.js'; 
import micStates from '../utils/mic_states.js'

const useMicStatus = () => {
  const chime = useContext(SessionManagerContext)
  const isMuted  = chime?.sessionManager?.meetingSession?.audioVideo?.realtimeIsLocalAudioMuted()
  const [micStatus, setMicStatus] = useState(isMuted ? micStates.muted : micStates.active)

  useEffect(() => {
     const callback = (muted) => {
       setMicStatus(muted ? micStates.muted : micStates.active)
     }
    chime.sessionManager?.meetingSession?.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(callback)
    return () => {
      if (chime && chime.sessionManager && chime.sessionManager.meeetingSession && chime.sessionManager.meetingSession.audioVideo) {
       chime.sessionManager.meetingSession.audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(callback)
      }
    }
 
  }, [])

  return micStatus
}

export default useMicStatus
