import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['slide']
  static values = {currentIndex: Number }

  connect() {
    let t = this;
    this.currentTimer = setTimeout(function(){t.nextSlide(true) }.bind(t), this.activeSlide( ).dataset.duration)
    
  }

  activeSlide( ) {
    return this.slideTargets[this.currentIndexValue]

  }

  previousSlide() {
    clearTimeout(this.currentTimer)
    let activeSlide =  this.activeSlide();
    let  t = this;
    if (activeSlide) {
      activeSlide.classList.remove("translate-x-0");
      activeSlide.classList.add("translate-x-full");
      this.currentIndexValue -= 1

      let previousSlide =  this.slideTargets[this.currentIndexValue]

      if (!previousSlide) {
        this.currentIndexValue = this.slideTargets.length - 1; 
        previousSlide = this.activeSlide()
      }

      this.repositionSlideToBeginning(previousSlide)

      if (previousSlide){
        previousSlide.classList.remove("-translate-x-full");
        previousSlide.classList.add("translate-x-0");
      }
    }
  }

  nextSlide(schedule) {
    if (schedule !== true) {
      clearTimeout(this.currentTimer)
    }

    let activeSlide = this.activeSlide();
    let t = this;
    this.currentIndexValue += 1

    if (activeSlide) {
      activeSlide.classList.remove("translate-x-0");
      activeSlide.classList.add("-translate-x-full");
      let nextSlide = this.slideTargets[this.currentIndexValue]

      if (!nextSlide) {
        this.currentIndexValue = 0; 
        nextSlide = this.activeSlide()
      }
      this.repositionSlideToEnd(nextSlide)

      if (nextSlide){
        nextSlide.classList.remove("translate-x-full");
        nextSlide.classList.add("translate-x-0");
        if (schedule === true) {
          this.currentTimer = setTimeout(function(){t.nextSlide(true)}.bind(t) , nextSlide.dataset.duration)
        }
      }
    }
  }


  repositionSlideToEnd(v){
    if (v.classList.contains('-translate-x-full')) {
      v.classList.remove('transition-all')
      v.classList.add('transition-none')
      v.classList.remove('-translate-x-full')
      v.classList.add('translate-x-full')
      v.offsetHeight; 
      v.classList.add('transition-all')
      v.classList.remove('transition-none')
    }

  }

  repositionSlideToBeginning(v){
    if (v.classList.contains('translate-x-full')) {
      v.classList.remove('transition-all')
      v.classList.add('transition-none')
      v.classList.remove('translate-x-full')
      v.classList.add('-translate-x-full')
      v.offsetHeight; 
      v.classList.add('transition-all')
      v.classList.remove('transition-none')
    }

  }


}


