import React, {useState, useEffect} from 'react'
import { CheckAudioInputFeedback, CheckVideoInputFeedback } from 'amazon-chime-sdk-js'; 
import MicOff from './mic_off.js'
import MicOn from './mic_on.js'
import CameraOff from './camera_off.js'
import CameraOn from './camera_on.js'

const DeviceChecklist = (props) => {
  const {checker, VideoInput, AudioInput, AudioOutput} = props
  const [status, setStatus] = useState({}) 
  const mapping = {
    audioFeedback: (res) => { 
      let status;
      switch(res) {
        case CheckAudioInputFeedback.Succeeded:
          status = 'Succeeded '
          break;
        case CheckAudioInputFeedback.Failed:
          status = 'Failed to detect a microphone. Is it connected?'
          break;
        case CheckAudioInputFeedback.PermissionDenied:
          status = 'It looks like you have not gave permission to access your microphone';
          break;
      }
      return status
    } ,
    videoFeedback: (res) =>  {
      let status;
      switch(res) {
        case CheckVideoInputFeedback.Succeeded:
          status = 'Succeeded '
          break;
        case CheckVideoInputFeedback.Failed:
          status = 'Failed to detect a camera. Is it connected?'
          break;
        case CheckVideoInputFeedback.PermissionDenied:
          status =  'It looks like you have not gave permission to access your camera'; 
          break;
      }
      return status
    }
  }

  useEffect(() => { 
    let shouldRender = true
    if (checker) {
      const vResult = async ( ) => { 
        let result1
        let result2
        try {
       if (shouldRender) {
         result1 = await checker.checkVideoInput((VideoInput && VideoInput.deviceId) || 'default') 
         result2 = await checker.checkAudioInput((AudioInput && AudioInput.deviceId) || 'default') 
       }
        }
        catch(e) {
          console.log(e)
        }
        const videoMessage = mapping.videoFeedback(result1)
        const audioMessage = mapping.audioFeedback(result2)
        if (shouldRender) {
        setStatus({videoFeedback: videoMessage, audioFeedback: audioMessage })
        }

      }
      vResult()
    }
    return () => shouldRender = false;
  }, [])

  return (
    <div className="mt-6" data-testid='jst-device-checklist'>
      <div className="flex flex-col border-2 border-gray-light rounded-lg shadow-md px-4 py-6">
        <div className="flex flex-row my-5 ">
          {status.videoFeedback && (
            <>
              <div className='w-1/5'>

                <button className='btn-off relative' disabled={true}> 
                  <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
                    {status.videoFeedback === 'Succeeded' ?  <CameraOn /> :  <CameraOff /> }
                  </div>
                </button>
              </div>
              <div className='w-4/5 ml-3'> 
                <h3 className='font-semibold'>Camera Availability</h3>

                <small data-testid='jst-videoFeedback'>
                  {status.videoFeedback}
                </small>
              </div>
            </>
          )
          }
        </div>
        <div className="flex flex-row my-5">
          {status.audioFeedback && (
            <>
              <div className='w-1/5'>
                <button className='btn-off relative ' disabled={true}> 
                  <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
                    {status.audioFeedback === 'Succeeded' ?  <MicOn /> :  <MicOff /> }
                  </div>
                </button>
              </div>
              <div className='ml-3 w-4/5'> 
                <h3 className='font-semibold '>Mic Availability</h3>
                <small data-testid='jst-audioFeedback'>
                  {status.audioFeedback}
                </small>
              </div>
            </>
          )
          }
        </div>
      </div>
    </div>
  )
}

export default DeviceChecklist;
