const selectedClass =  ''
const nonSelectedClass = ''
import { Controller } from 'stimulus'; 
import Rails from '@rails/ujs'; 
export default class extends Controller {
  static targets = ['text']
  static values = {
    endpoint: String
  }
  connect() {
 
  }

  disconnect(){
  }

  create(e) {
    e.preventDefault()
    let t = this;
    Rails.ajax({
      type: 'post', 
      url: t.endpointValue,
      dataType: 'json', 
      success: function(resp){
        e.target.classList.add('text-apricot')
        e.target.classList.remove('text-gray')
        t.textTarget.innerText = 'Pinned To Interests'
        e.target.dataset.action = 'click->events--interests#destroy'
      }, 
      failure: function(resp) {
        alert(resp.message);

      }
    })

  }

 destroy(e) {
  e.preventDefault()
    let t = this;
    Rails.ajax({
      type: 'delete', 
      url: t.endpointValue.replace(/\?room_id\=/, '/'),
      dataType: 'json', 
      success: function(resp){
        e.target.classList.add('text-gray')
        e.target.classList.remove('text-apricot')
        t.textTarget.innerText = 'Pin to Interests'
        e.target.dataset.action = 'click->events--interests#create'
      }, 
      failure: function(resp) {
        alert(resp.message);

      }
    })
  }

}


