import React, {useContext} from 'react'; 
import PresenterVideo from '../presenter_video.js'; 
import Presenter from '../presenter.js'; 
import PresenterControls from '../presenter_controls.js'; 
import RequestInfo from '../request_info.js'; 
import ReceptionInfo from '../reception_info.js'; 
import Handouts from '../handouts.js'; 
import PresenterToggleControlsContext from "../presenter_toggle_controls_context.js";
import ApiContext from '../api_context.js'
import RoomControls from '../room_controls.js'
const LeftColumn  = (props) => {
 const api = useContext(ApiContext)
 return (
  <div className="flex flex-col order-1 lg:order-none lg:w-1/4 my-5 lg:mr-5">
              {!props.loading && props.user.role !== "presenter" && (
                <div>
                  <div className="px-2 py-1 bg-gradient-to-r from-apricot-dark to-apricot-light mb-3">
                    <h2 className="px-1 py-1 text-lg text-white font-semibold">
                      PRESENTER
                    </h2>
                  </div>
                   <PresenterVideo
                       findUser={props.findUser}
                    />
                  {props.presenters.map((d, i) => {
                    return (
                      <Presenter
                        key={i}
                        findUser={props.findUser}
                        presenter={d}
                        ceu_credits={props.session_data.ceu_credits}
                      />
                    );
                  })}
                </div>
              )}
              {!props.loading && props.user.role == "presenter" && (
                <div>
                   <PresenterVideo
                       findUser={props.findUser}
                    />
                  <div>
                      <PresenterToggleControlsContext.Provider
                        value={{
                          muteToggleOn: api.muteToggleOn,
                          toggleRoomLock: api.toggleRoomLock,
                          toggleMute: props.toggleMute,
                          user: props.user, 
                          findUser: props.findUser
                        }}
                      >
                     <div className="flex flex-col border-2 border-gray-light rounded-lg shadow-md px-8 py-8 h-100">
                       {props.ready &&
                        <PresenterControls
                          sessionManager={props.sessionManager}
                          roomIsLocked={props.roomIsLocked}
                          defaultCamera={props.VideoInput}
                        />
                        }
                        <RoomControls {...props} />
                      </div>
                     </PresenterToggleControlsContext.Provider>
                  </div>
                </div>
              )}
              {props.session_data &&
                props.session_data.type !== "ReceptionRoom" &&
                props.user.role !== "presenter" && (
                  <div className="">
                    <div className="my-4">
                      {!props.loading && (
                        <RequestInfo
                          room_id={props.session_data.room_id}
                          aia_number={props.session_data.aia_number}
                          accreditationRequested={
                           props.session_data.accreditation_requested
                          }
                          presenter={props.presenters[0]}
                          ceu_credits={props.session_data.ceu_credits}
                        />
                      )}
                    </div>
                    <div className="mt-10">
                      {props.session_data.handouts.length > 0 && (
                        <Handouts
                          attachments={props.session_data.handouts}
                        />
                      )}
                    </div>
                  </div>
                )}
              {props.session_data &&
                props.session_data.type === "ReceptionRoom" && (
                  <ReceptionInfo {...props.session_data.reception_info} />
                )}
            </div>
   ); 
}
export default LeftColumn; 
