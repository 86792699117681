import React, { useState } from "react";
import useTimeAgo from "@rooks/use-time-ago";

const ChatMessage = (props) => {
  const message = props.message;
  const [date, setDate] = useState(new Date(message.created_at));
  const timeAgo = useTimeAgo(date.getTime());
  let float = floatForMessage(props.fromCurrentUser);
  let userNameHTML = <small> </small>;
  userNameHTML =
    props.chatType === "support"
      ? nameHtmlForSupportChat(message, float)
      : nameHtmlForRoomChat(message);
  const messageFloat =
    props.chatType === "support"
      ? float
      : message.user.role === "presenter"
      ? "justify-start"
      : "justify-end";
  return (
    <div key={message.id} className="mb-12 overflow-hidden p-3">
      <div className={`flex ${messageFloat}`}>{userNameHTML}</div>
      <div className={`flex ${messageFloat}`}>
        <div
          className={`bg-white w-3/4 mx-1 my-2 p-3 rounded-lg break-words whitespace-normal`}
          dangerouslySetInnerHTML={{ __html: message.message }}
        ></div>
      </div>
      <div className={`flex ${messageFloat}`}>
        <small
          className={`w-36 ${
            messageFloat === "justify-end" ? "text-right mr-2" : "ml-2"
          }`}
        >
          {timeAgo}
        </small>
      </div>
    </div>
  );
};

const nameHtmlForRoomChat = (message) => {
  switch (message.user.role) {
    case "presenter":
      return [
        <small
          key={1}
          className="bg-apricot-light text-white font-bold py-0.5 px-3 rounded-full text-center inline-block text-xxs"
        >
          Presenter
        </small>,
        <small key={0} className=" mx-3 font-bold">
          {message.user.name}
        </small>,
      ];
      break;
    case "agent":
      return [
        <small
          key={1}
          className="bg-apricot-light text-white font-bold py-0.5 px-3 
        rounded-full text-center inline-block text-xxs"
        >
          Agent
        </small>,
        <small key={0} className="mx-3">
          {message.user.name}
        </small>,
      ];
      break;
    default:
      return <small className="mx-3">{message.user.name}</small>;
      break;
  }
};

const nameHtmlForSupportChat = (message) => {
  switch (message.user.role) {
    case "specifier":
      return [
        <small
          key={1}
          className={`bg-blue-light text-white font-bold py-0.5 
        px-3 rounded-full text-center inline-block text-xxs`}
        >
          Attendee
        </small>,
        <small key={0} className={`mx-3 font-bold`}>
          {message.user.name}
        </small>,
      ];
      break;
    case "agent":
      return [
        <small
          key={1}
          className={` bg-apricot-light text-white font-bold py-0.5 px-3 rounded-full text-center inline-block text-xxs`}
        >
          Agent
        </small>,
        <small key={0} className={` mx-3`}>
          {message.user.name}
        </small>,
      ];
      break;
    default:
      return <small className={` mx-3`}>{message.user.name}</small>;
      break;
  }
};

const floatForMessage = (fromCurrentUser) => {
  let float = fromCurrentUser ? "justify-start" : "justify-end";
  return float;
};

export default ChatMessage;
