import RemoteReactSelect from 'remote-react-select'
import React from "react";
import ReactDOM from "react-dom";
import { Controller } from "stimulus"; 
export default class extends Controller {
  static get targets() {
    return [''];
  }

  connect() { 
    this.reactProps =  JSON.parse(this.data.get('react-props')); 
    this.reactRender(); 
    this.element[this.identifier] = this;
  }

  updateProp(key, value) {
    ReactDOM.unmountComponentAtNode(this.element); 
    this.reactProps[key] = value
    this.reactRender()

  }

  reactRender(){
    let  elem = RemoteReactSelect;
    if (elem)  {
    return ReactDOM.render(
    React.createElement(elem, this.reactProps, null), this.element); 
    }
    else {
      console.warn('No react element found' ) ; 
    }
  }

}
