import React, { useState, useEffect } from "react";
import cameraStates from '../event_rooms/utils/camera_states.js'
//split this into camera hook and mic hook
const MicCameraToggling = (props, layout) =>  {
  // const  [camera_toggle, setCameraButton] = useState(false);
  const _props = {
    canUnmute: props.canUnmute, 
    handleAudioClick:  () => {
    if (
      props.sessionManager.meetingSession.audioVideo.realtimeIsLocalAudioMuted()
    ) {
      props.sessionManager.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } else {
      props.sessionManager.meetingSession.audioVideo.realtimeMuteLocalAudio();
    }
  },
    handleCameraClick:  () => {
     props.sessionManager.toggleVideo(props.defaultCamera).then((toggle) => {
      if (props.defaultCamera){
        props.sessionManager.videoTogglePref = toggle
        let v = toggle ? cameraStates.active : cameraStates.off
        props.sessionManager.publishLocalVideoUpdate(v)
      }
      else {
        throw('No camera'); 
      }
    }).catch((e) =>{
      console.log(e)
      alert('There is no camera available')
    });
  } 
  }

  return layout(_props)
}

export default MicCameraToggling




