import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['select']

  connect(){
 
  }

  switchType(e) {
    this.selectTarget['remote-select-tag'].updateProp('endpoint', e.target.dataset.endpoint)
  }

}
