import React from "react";

const Loader = (props) => {
  const containerOrientation = props.elementOriented ? "absolute" : "fixed";

  return (
   <>
    <div
      data-testid={props.testid}
      className={
        containerOrientation +
        " w-full h-full block right-0 left-0 top-0 left-0 bg-white opacity-75 z-50 pointer-events-none"
      }
    >
      <span className="text-green opacity-75 top-1/2 my-0 mx-auto block text-center relative w-20 h-20">
        <img className="animate-spin" src="/LEX_3.png"></img>
      </span>
    </div>
    {props.text &&
        <div className={ containerOrientation + " w-full h-full block right-0 left-0 top-0 left-0  z-50 pointer-events-none"} >
          <p className='opacity-100 my-24 top-1/2 mx-auto block text-center relative text-white rounded bg-gray-dark p-3  w-64 h-20 z-75'> {props.text} 
          </p> 
        </div>
     }
    </>

  );
};

export default Loader;
