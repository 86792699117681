import React from "react";
import ConferencingSession from "../shared/conferencing";
import preRoomSession from './providers/pre_room_session.js'
import EntryControls from "../event_rooms/entry_controls.js";
import AudioViz from "./audio_viz.js";
import VideoTile from "./video_tile.js";
import DeviceObserver from './observers/device_observer.js'
import DeviceChecklist from './device_checklist.js'
import { DefaultAudioVideoFacade,  DefaultMeetingReadinessChecker, IntervalScheduler, ConsoleLogger, LogLevel } from 'amazon-chime-sdk-js'; 
import Svgx from '../shared/svgx.js'
import deviceFilter from '../shared/device_filter.js'
const background = "/Low-Poly-Background.jpg";
const logger = new ConsoleLogger('MyLogger', LogLevel.WARN);
const setupState = {'initial':  0, 'devicesFetched' : 1, 'defaultDevicesSet': 2, 'videoPreviewStarted': 3, 'micPreviewStarted': 4, 'ready': 5, 'needPermissions': 6} 

export default class PreRoomSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setupComplete: false,
      audioInputDevices: [],
      audioOutputDevices: [],
      videoInputDevices: [],
      micEnabled: false,
      setupState: 0,
      autoEnter: new URLSearchParams(window.location.search).get("auto-enter")
    };
    this.preview = {};
    this.changeDevice = this.changeDevice.bind(this);
    this.enterRoom = this.enterRoom.bind(this);
    this.stopPreview = this.stopPreview.bind(this);
    this.createAnalyzeNode = this.createAnalyzeNode.bind(this);
    this.stopMicPreview = this.stopMicPreview.bind(this);
    this.micPreview = this.micPreview.bind(this);
    this.audioTest = React.createRef()
     this.playTestAudio = this.playTestAudio.bind(this)
  }

 
  componentDidMount() {
    let t = this;
    t.sessionManager = new ConferencingSession();
    t.meetingReadinessChecker = new DefaultMeetingReadinessChecker(logger) 
    t.meetingReadinessChecker.meetingSession = {audioVideo: new DefaultAudioVideoFacade({configuration: 
      {credentials: {}}, logger: logger},{},{},{}, this.sessionManager.deviceController ), configuration: {credentials: {}}}
    this.observer = DeviceObserver(t)
    t.sessionManager.deviceController.addDeviceChangeObserver(this.observer)
    t.initializeDevices()
  }

  componentWillUnmount() {
    this.stopPreview();
    this.sessionManager.deviceController.removeDeviceChangeObserver(this.observer)
  }

  initializeDevices(){
    let t = this;
    t.listDevices().then(function (values) {
      let av = {}
      values.forEach((d, i) => {
        let key = Object.keys(d)[0]
        av[key] = d[key]
      })
      if (av.videoInputDevices.length === 0) {
        t.sessionManager.deviceController.setDeviceLabelTrigger(() => {
          return navigator.mediaDevices.getUserMedia({audio: true, video: false })
        })
      }
      t.setState({setupState: setupState.devicesFetched, ...av});
     }).catch(function(values) {
       console.log(values)
      if (t.state.videoInputDevices.length === 0) {
        t.sessionManager.deviceController.setDeviceLabelTrigger(() => {
          return navigator.mediaDevices.getUserMedia({audio: true, video: false })
        })
      }
      t.sessionManager.fetchAudioInput().then(() => {
      t.setState({setupState: setupState.devicesFetched}) 
      })
    })
  }

  async playTestAudio(){
    let t = this;
    await t.meetingReadinessChecker.checkAudioOutput(
      t.state.AudioOutput, () => {
        return new Promise(resolve => {
          const scheduler = new IntervalScheduler(1000);
           scheduler.start(() => {
            if (this.canHear !== null) {
              scheduler.stop();
              resolve(this.canHear);
            }
          });
        })
      })
  }

  componentDidUpdate() {
    let t = this;
    switch(this.state.setupState) {
      case setupState.devicesFetched: 
        this.setDefaultDevices()
        break;
      case setupState.defaultDevicesSet:
        this.startPreview(true); 
        break; 
      case setupState.videoPreviewStarted: 
        this.micPreview(true); 
        break; 
      case setupState.micPreviewStarted: 
        this.requirementsMet() ? this.setState({setupState: setupState.ready}) : this.setState({setupState: setupState.needPermissions})
        break;
     }
      if (
        (this.state.setupState === setupState.ready)  &&
        this.state.autoEnter &&
        !this.state.setupComplete
      ) {
        this.enterRoom();
      }
   }

  enterRoom() {
    let t = this;
    this.analyzeNode && this.analyzeNode.removeOriginalInputs();
    const startsWithVideo = t.videoActive();
    this.stopPreview();
    if (!t.props.isPresenter) { 
    //lower video upload quality
    t.sessionManager.deviceController.chooseVideoInputQuality(320, 180, 15, 250) 
    }
    t.sessionManager
      .setDeviceById(t.state.VideoInput, "VideoInput")
      .then(() => {
        t.setState({ setupComplete: true, startsWithVideo: startsWithVideo });
      });
  }

  micPreview(changeState) {
    let t = this
    t.createAnalyzeNode().then(() => {
      let changeSetupStatus =  changeState ? {setupState: setupState.micPreviewStarted} : {}
      t.setState({ micEnabled: true, ...changeSetupStatus});
    })
   }

  createAnalyzeNode() {
    let t = this
    return new  Promise((resolve, reject) => {
      t.analyzeNode = t.sessionManager.deviceController.createAnalyserNodeForAudioInput();
      setTimeout(()=> resolve(true), 500)
    })
  }

  stopMicPreview() {
    this.analyzeNode && this.analyzeNode.removeOriginalInputs();
    this.setState({ micEnabled: false });
  }

  changeDevice(e) {
    let t = this;
    this.sessionManager
      .setDeviceById(e.target.value, e.target.name)
      .then(() => {
        let state = {};
        state[e.target.name] = e.target.value;
        if (e.target.name === "AudioInput") {
          t.analyzeNode && t.analyzeNode.removeOriginalInputs();
          t.createAnalyzeNode();
        }
        t.setState(state);
      });
  }

  toggleVideo() {
    let t = this;
    return new Promise((resolve, reject) => {
      if (t.videoActive()) {
        t.stopPreview();
        resolve(false);
      } else {
        t.sessionManager
          .setDeviceById(t.state.VideoInput, "VideoInput")
          .then(() => {
            t.startPreview();
            resolve(true);
          });
      }
    });
  }

  videoActive() {
    return this.preview.tile &&  this.preview.tile.current && this.preview.tile.current.srcObject
      ? true
      : false;
  }

  controls() {
    return new preRoomSession(this)
  }

  startPreview(changeState) {
    let t = this;
    if (!this.preview.tile) return false;
    if (!this.preview.tile.current) return false; 
    try {  
    this.sessionManager.deviceController.startVideoPreviewForVideoInput(
      this.preview.tile.current
    );
    }
    catch (e){
      console.log('unable to start video preview')
    }
    if (changeState) {
    t.setState({ setupState: setupState.videoPreviewStarted });
    }
  }

  stopPreview() {
    if (!this.preview.tile) return false;
    if (!this.preview.tile.current) return false; 
    this.sessionManager.deviceController.stopVideoPreviewForVideoInput(
      this.preview.tile.current
    );
  }

  listDevices() {
    let t = this;
    let promises = [] 

     promises.push(new Promise(function (resolve, reject) {
      t.sessionManager.fetchAudioOutput().then(function (devices) {
        // if (devices.filter((d, i) =>  d.deviceId !== '').length < 1 ) {
        //   throw('Audio Output device is invalid')
        // }
   //     t.setState({ audioOutputDevices: devices });
        resolve({ audioOutputDevices: devices }); 
      }).catch(() => {
        reject({audioOutput: false })
      })
     }))

    promises.push(new Promise(function (resolve, reject) {
      t.sessionManager.fetchAudioInput().then(function (devices) {
        // if (devices.filter((d, i) =>  d.deviceId !== '').length < 1 ) {
        //   throw('Audio Input device is invalid')
        //  }
  //      t.setState({ audioInputDevices: devices });

        resolve({audioInputDevices: devices}); 
      }).catch(() => {
        reject({audioInput: false })
      })
     }))

   promises.push(new Promise(function (resolve, reject) {
      t.sessionManager.fetchVideoInput().then(function (devices) {
       // if (devices.filter((d, i) =>  d.deviceId !== '').length < 1 ){
       //    throw('Audio Output device is invalid')
       //  }

 //     t.setState();
        resolve({ videoInputDevices: devices }); 
      }).catch(() => {

        reject({videoInput: false })
      })
     }))
     return Promise.all(promises) 

  }

  setDefaultDevices() {
    let t = this;
    let promises = [];
    let data = ["AudioInput", "VideoInput", "AudioOutput"];
      data.map(function (key) {
        let deviceListKey = key[0].toLowerCase() + key.slice(1) + "Devices";
        let device = t.state[deviceListKey][0]
          ? t.state[deviceListKey][0].deviceId
          : null;
        promises.push(
          t.sessionManager
            .setDeviceById(device, key)
            .then(() => {
              return { device: key, set: true, deviceId: device };
            })
            .catch((e) => {
              console.log(e)
              return { device: key, set: false };
            })
        );
      });
      Promise.all(promises).then(function (data) {
        let enabledDevices = {};
        data.forEach((d) => {
          if (d.set) {
            enabledDevices[d.device] = d.deviceId;
          }
        });

        t.setState({ setupState: setupState.defaultDevicesSet,  ...enabledDevices });
      }).catch((e)=> {
        t.setState({setupState: setupState.defaultDevicesSet})
      })
      ;
  }
  requirementsMet() {
    return  deviceFilter(this.state.audioInputDevices) 

  }

  render() {
    let t = this;
    const _props = {
      audioOutputDevices: t.state.audioOutputDevices,
      audioInputDevices: t.state.audioInputDevices,
      videoInputDevices: t.state.videoInputDevices,
      sessionManager: t.sessionManager,
      VideoInput: t.state.VideoInput,
      AudioInput: t.state.AudioInput,
      AudioOutput: t.state.AudioOutput,
      startsWithVideo: t.state.startsWithVideo,
      micEnabled: t.state.micEnabled,

    };

    return !this.state.setupComplete ? (
      <div className="min-h-screen">
        <div className="mx-auto py-6 sm:px-4 lg:px-8 px-2 lg:w-4/5 w-11/12">
          <div
            className="px-2 py-1 bg-cover bg-bottom mx-auto lg:mt-16 mb-8"
            style={{ backgroundImage: `url(${background})` }}
          >
            <h2 className="px-1 py-1 text-xl text-white font-semibold">
              Check Your Audio and Video
            </h2>
          </div>
            <div className="flex lg:flex-row flex-col lg:w-11/12 w-full mx-auto">
              <div className="lg:w-3/5 w-full order-last lg:order-none">
                <VideoTile
                  tile={this.preview}
                  tileData={this.preview}
                  user={{ name: "Setup" }}
                  hideNameTag={true}
                ></VideoTile>
                {this.requirementsMet() && ( 
                <a
                  className="btn block rounded-lg text-center text-lg mt-5 py-3 w-full md:w-1/2 lg:w-1/3 mx-auto cursor-pointer bg-purple-light"
                  role='enter-room-btn'
                  onClick={this.enterRoom}
                >
                  Enter Room
                </a>
                )
                }
            {!this.requirementsMet() && ( 
                <button
                  disabled={true}
                  className="btn disabled:opacity-50 block rounded-lg text-center text-lg mt-5 py-3 w-full
                  md:w-3/4 lg:w-1/2 mx-auto cursor-not-allowed bg-purple-light"
                  role='enter-room-btn'
                >
                  You must complete setup before entering
                </button>
                )
                }
                <p className="text-gray-400 text-center my-6">
                  Your microphone will be automatically muted upon entry.
                </p>
              </div>
              <div className="lg:w-2/5 md:w-1/2 mx-auto lg:mx-none w-full lg:ml-16 mb-8 lg:mb-0">
                <div className="p-6 bg-apricot-dark text-white rounded-lg inline-block jst-setup-tip-box">
                  <div className="flex flex-row items-center mb-2">
                    <Svgx
                      svgKey={this.requirementsMet() ? 'thumb-up' : 'exclamation'}
                      options={{className:'h-8 w-8 mr-2'}}
                    />
                  <p className="text-2xl font-bold">{this.requirementsMet() ? 'PRO Tips' : 'Check Your Devices'}</p>
                  </div>
                  {(this.state.setupState === setupState.ready) &&  
                  <p>
                    Make sure you are well lit and that your camera is on. We
                    can't wait to see your friendly face!
                  </p>
                  }
                  {(this.state.setupState === setupState.needPermissions)  &&  
                  <p>
                    It looks like some of the necessary devices are not connnected or you have not given permission for your browser to access them. 
                    You must have at least have a speaker and a microphone connected to access the room
                  </p>
                  }
                </div>
                {(this.state.setupState === setupState.ready) && (
                  <EntryControls
                    sessionManager={this.controls()}
                    {...this.state}
                    changeDevice={this.changeDevice}
                    analyzeNode={this.analyzeNode}
                    playTestAudio={this.playTestAudio}
                    defaultCamera={this.state.VideoInput}
                  />
                )}
                {this.state.setupState === setupState.needPermissions && (
                  <DeviceChecklist 
                    checker={this.meetingReadinessChecker}
                    VideoInput={this.state.VideoInput}
                    AudioInput={this.state.AudioInput}
                   />
                )}
              </div>
            </div>
        </div>
      </div>
    ) : (
      this.props.children(_props)
    );
  }
}
