import React from "react";
import { createConsumer } from "@rails/actioncable";
import { messageReceived, sendMessage } from "../shared/chat_shared.js";
import PreRoomSetup from "./pre_room_setup.js";
import RosterFlyout from "./roster_flyout.js";
import ApiContext from "./api_context.js";
import SessionObserver from "./observer.js";
const consumer = createConsumer();
export default class Roster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attendees: [],
      messages: [],
      session_loaded: "",
      rosterVisible: false,
      roomIsLocked: false,
      user: '' 
    }

    this.toggleRoster = this.toggleRoster.bind(this); 
    this.attendeeSet = new Set()
    this.addAttendee = this.addAttendee.bind(this); 
    this.removeAttendee = this.removeAttendee.bind(this); 
  }

  static contextType = ApiContext;

  toggleRoster(e) {
    this.setState({ rosterVisible: !this.state.rosterVisible });
  }

  componentWillUnmount() {
    if (this.consumer) {
      this.consumer.unsubscribe();
    }
    this.mounted = false;
  }

  attendeeKeyMap( ){
    let map =  {}
    for (let [key, value] of this.attendeeSet.entries()) {
      if (key.id) {
        map[key.id] = key
      }
    }
    return map
  }

  addAttendee(user, attendeeId) {
    let t = this;
    return new Promise((resolve, reject) => {
    if (!user) {
      throw('User Id is not present'); 

    }

    if (t.attendeeKeyMap()[user]){

      let d = t.attendeeKeyMap()[user]
      d.attendeeId = attendeeId
      t.attendeeSet.add(d)
      t.setAttendees()
      resolve(d)
    }
    else {
    t.context.fetchAttendee(user, (data) => {
      let d = data.roster[0]
      d.attendeeId = attendeeId
      t.attendeeSet.add(d)
      t.setAttendees()
      resolve(d)
    }, 
    (error) => {
      console.log(error); 
      reject(error); 
    })
    }
    })
  }

  removeAttendee(user) {
    let t = this;
    return new Promise((resolve, reject) => {
    t.attendeeSet.delete(user)

     t.setAttendees()
     resolve(true)
    }) 
  }

  setAttendees(){
   const attendees =  Array.from(this.attendeeSet).sort(
      (a, b) => a.join_time - b.join_time
    )
   this.setState({attendees: attendees})
  }

  componentDidMount() {
    this.mounted = true;
    let t = this;
    t.context.fetchRoomSession(
      function (data) {
        t.consumer = consumer.subscriptions.create(
          { channel: "RoomRosterChannel", room: t.props.room_id },
          {
            received: (data) => {
              if (data.action === "speak") {
                messageReceived(t, data);
              }
              if (data.action === "update_locked_status") {
                t.setState({ roomIsLocked: data.is_locked });
              }

              if (data.action === 'update_mute_toggle_status') {
                t.context.publishMuteToggleUpdate(data.mute_toggle_on)
              }
            },
          }
        );
        let user = { id: data.id, role: data.role, name: data.name };
        if (t.mounted) {
          const presenters = [data.presenter, data.co_presenter].filter(
            (el) => el 
          );
          if (data.roster  && data.roster.length > 0) {
            data.roster.forEach((attendee, i) => {
              t.attendeeSet.add(attendee)
            })
          }
          t.context.muteToggleOn = data.mute_toggle_on
          t.setState({
            session_data: data,
            user: user,
            messages: data.most_recent_messages,
            roomIsLocked: data.is_locked,
            presenters: presenters,
            session_loaded: true,
          });
        }
      },
      function (errors) {
        alert(errors.errors);
        window.location.href = t.props.mainFloorUrl;
      }
    );
  }

  render() {
    const _props = {
      ...this.state,
      room_id: this.props.room_id, 
      toggleRoster: this.toggleRoster,
      addAttendee: this.addAttendee,
      removeAttendee: this.removeAttendee
    }

    return (
      <div>
        {this.state.rosterVisible && (
          <RosterFlyout
            attendees={this.state.attendees}
            toggleRoster={this.toggleRoster}
          />
        )}
        {this.props.children(_props, sendMessage.bind(this))}
      </div>
    );
  }
}
