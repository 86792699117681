import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    seconds: Number
  }
  static targets = ['audio']
  initialize(){
    let t = this
    if (t.hasAudioTarget) {
      t.audioTarget.load()
    }
    if (this.secondsValue <= 0) {
      return; 

    }
    setTimeout(() => 
    {
    Turbolinks.visit(window.location, {action: 'replace'})
      t.playAudio()
     }, this.secondsValue * 1000)

  }

  playAudio(){
   let t = this;
   if (t.hasAudioTarget) {
     t.audioTarget.play()
    }
  }


}
