import React, { useState, useEffect } from "react";
import Svgx from "../shared/svgx.js";
const RosterFlyout = (props) => {
  const agents = props.attendees.filter(
    (attendee) => attendee.role === "agent"
  );
  const attendees = props.attendees.filter(
    (attendee) => attendee.role === "attendee"
  );
  const presenters = props.attendees.filter(
    (attendee) => attendee.role === "presenter"
  );

  const colorClassFor = (attendee) => {
    switch (attendee.role) {
      case "presenter":
        return "bg-apricot";
        break;
      case "agent":
        return "bg-blue-dark";
        break;
      default:
        return "bg-blue-light";
        break;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  const renderAttendee = (attendee) => {
    return (
      <li key={attendee.id}>
        <div className="flex space-x-2 items-center my-3">
          <div
            className={
              colorClassFor(attendee) + " pill text-white align self-start mt-1"
            }
          >
            {attendee.role.replace(/^./, attendee.role[0].toUpperCase())}
          </div>
          <div>
            <span className="font-bold"> {attendee.name}</span>
            <div>
              <small className="italic">{attendee.company_name}</small>
            </div>
          </div>
        </div>
      </li>
    );
  };

  const handleOffClick = (e) => {
    if (e.target.id === "room-attendance-flyout-container") {
      props.toggleRoster();
    }
  };

  return (
    <div
      onClick={handleOffClick}
      id="room-attendance-flyout-container"
      data-testid='jst-attendance-flyout'
      className="z-50 absolute top-0 right-0 w-full h-full flex justify-end"
    >
      <div
        id="room-attenance-flyout"
        className="shadow-md w-3/4 md:w-1/5 p-5 bg-gray-light
        ml h-full min-h-screen  overflow-y-auto"
      >
        <div className="flex my-8 flex justify-between items-center">
          <h2 className="text-2xl font-bold">Attendance</h2>
          <a
            onClick={props.toggleRoster}
            className="block text-right cursor-pointer mr-5"
          >
            Close
            <Svgx
              svgKey={"arrow-right"}
              options={{ className: "inline", width: "20px", height: "20px" }}
              size={"small"}
            />
          </a>
        </div>
        {presenters.length > 0 && (
          <ul className="space-y-2 py-2 border-b-2 border-light-gray">
            {presenters.map((att, i) => {
              return renderAttendee(att);
            })}
          </ul>
        )}
        {agents.length > 0 && (
          <ul className="space-y-2 py-2 border-b-2 border-light-gray">
            {agents.map((att, i) => {
              return renderAttendee(att);
            })}
          </ul>
        )}
        {attendees.length > 0 && (
          <ul className="space-y-2 py-2 border-b-2 border-light-gray">
            {attendees.map((att, i) => {
              return renderAttendee(att);
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default RosterFlyout;
