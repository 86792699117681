// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "stimulus"
import Flatpickr from 'stimulus-flatpickr'
import { definitionsFromContext } from "stimulus/webpack-helpers"
require('stylesheets/application.scss')
import ContentLoader from 'stimulus-content-loader'
import Clipboard from 'stimulus-clipboard'
import NestedForm from 'stimulus-rails-nested-form' 
const application = Application.start()
const context = require.context("../controllers/", true, /\.js$/)
const MyFlatpickr = class  extends Flatpickr {

 initialize() {

   this.config = {
     locale: {
       rangeSeparator: '~'
     }
   }
  }

 connect() {

   super.connect()
   this.clearClicked = this.clearClicked.bind(this)
   if (this.data.has('clear-btn')){
     this.clearButton =  document.createElement('span')
     this.clearButton.innerHTML = this.data.get('clear-btn')
     this.clearButton.classList.add('-ml-8')
     this.element.classList.add('inline-block')

     this.inputTarget.insertAdjacentElement('afterend', this.clearButton)
     this.clearButton.addEventListener('click', this.clearClicked)

   }
 }

  disconnect(){
    if (this.clearButton) {
      this.clearbutton.removeEventListener('click', this.clearClicked)

    }

  }
 clearClicked(e){
   e.preventDefault()
   this.fp.clear()
 }
}


application.load(definitionsFromContext(context))
application.register('content-loader', ContentLoader)
application.register('clipboard', Clipboard)
application.register('flatpickr', MyFlatpickr)
application.register('nested-form', NestedForm)

Rails.start()
Turbolinks.start()
ActiveStorage.start()




