import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultVideoTile,
  LogLevel,
  MeetingSessionConfiguration, 
  DefaultModality
} from 'amazon-chime-sdk-js'; 
import { throttle, debounce } from 'lodash'; 
import cameraStates from '../event_rooms/utils/camera_states.js'


const logger = new ConsoleLogger('MyLogger', LogLevel.WARN);
const deviceController = new DefaultDeviceController(logger); 
const ROSTER_THROTTLE_MS = 400; 

export default class ConferencingSession  {

  constructor(){
    this.deviceController = deviceController
    this.videoTogglePref = false
    this.rosterUpdateCallbacks = [];
    this.localVideoCallbacks = [];
    let t = this; 
     this.publishRosterUpdate = throttle((role) => {
    for (let i = 0; i < t.rosterUpdateCallbacks.length; i += 1) {
      const callback = t.rosterUpdateCallbacks[i];
      callback(t.tileManager.tileIdToUserMap, role);
    }
    }, ROSTER_THROTTLE_MS);

   this.publishLocalVideoUpdate = throttle((camState) => {
    for (let i = 0; i < t.localVideoCallbacks.length; i += 1) {
      const callback = t.localVideoCallbacks[i];
      if (typeof camState !== 'undefined' )  {
        callback(camState)
      }
      else {
        callback(t.localVideoActive() ? cameraStates.active : cameraStates.off);
      }
    }
    }, 100);
  }

  setDeviceById(id, key){
    return this.deviceController[`choose${key}Device`](id); 
  }

  addTileManager(tileManager) {
   this.tileManager =  tileManager;
  }

  roster() {
   return this?.tileManager.tileIdToUserMap 
  }

 
  bindAudioAndVideo(audio, context){
    let t = this; 
    this.meetingSession.audioVideo.addContentShareObserver(this.observer);
    this.meetingSession.audioVideo.addObserver(this.observer);
    this.meetingSession.audioVideo.bindAudioElement(audio);
    this.meetingSession.audioVideo.start()
   
    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((presentAttendeeId, present, user) => {
        const baseAttendeeId = new DefaultModality(presentAttendeeId).base();
          if (baseAttendeeId !== presentAttendeeId) {
               return;
          }
        if (!present) {
          context.props.removeAttendee(context.findUser(user)).then((data) =>{
          context.tileManager.releaseVideoElement(user)
          context.tileManager.bindTilelessUsers(context.props.attendees.filter((u) => u.id !== user))
          delete context.tileManager.tileIdToUserMap[presentAttendeeId] 
          this.publishRosterUpdate.cancel();
          this.publishRosterUpdate();

          })
          return;
        }


        this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId, async (attendeeId, volume, muted, signalStrength) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base();
            if (baseAttendeeId !== attendeeId) {
               return;
            }

            let shouldPublishImmediately = false;
            if (!context.tileManager.tileIdToUserMap[attendeeId]){
              context.tileManager.tileIdToUserMap[attendeeId] = {name: ''};  
            }

           if (volume !== null) {
              context.tileManager.tileIdToUserMap[attendeeId].volume = Math.round(volume * 100);
            }
            if (muted !== null) {
              context.tileManager.tileIdToUserMap[attendeeId].muted = muted;
            }
            if (signalStrength !== null) {
              context.tileManager.tileIdToUserMap[attendeeId].signalStrength = Math.round(
                signalStrength * 100
              );
            }


            if (attendeeId && !context.tileManager.tileIdToUserMap[attendeeId].id) {
              context.tileManager.tileIdToUserMap[attendeeId].id = user
              const data = await context.props.addAttendee(user, attendeeId) 
              if (!context.tileManager.tileIdToUserMap[attendeeId]) return; //map was deleted during add attendee promise
              
              context.tileManager.bindUsers([data])
              context.tileManager.tileIdToUserMap[attendeeId].name = data  && data.name
              context.tileManager.tileIdToUserMap[attendeeId].role = data  && data.role
              shouldPublishImmediately = true; 
            }

            if (shouldPublishImmediately) {
              this.publishRosterUpdate.cancel();
            }
            this.publishRosterUpdate(context.tileManager.tileIdToUserMap[attendeeId].role);
          }
        );
      } 
    );

    context.setState({ran_once: true}) 
  }

 
  

  endUserSession(){
    this.meetingSession.audioVideo.stop()
    this.removeObserver()
  }

  removeObserver(){
    this.meetingSession.audioVideo.removeObserver(this.observer); 
  }

  bindVideo(tileId, element) {
    this.meetingSession.audioVideo.bindVideoElement(tileId, element)
  }


  buildObserver(obj) {
    this.observer = obj
    return this
  }

  toggleVideo(device){
    let t = this;
    return new Promise((resolve, reject)=> {
      if (this.localVideoActive()) {
        this.meetingSession.audioVideo.stopLocalVideoTile()
        resolve(false);
      }
      else {
        this.setDeviceById(device, 'VideoInput').then( () => {
          t.meetingSession.audioVideo.startLocalVideoTile()        
          resolve(true);
        })
      }
    })
  }

  startVideo(device){
    let t = this;
    return new Promise((resolve, reject) => {
      this.setDeviceById(device, 'VideoInput').then( () => {
          t.meetingSession.audioVideo.startLocalVideoTile()        
          resolve(true);
    })
    })
  }

  localTile(){
    let tile = this.meetingSession.audioVideo.getLocalVideoTile();
    return tile &&  tile.tileState; 
  }

  localVideoActive() {
    return this.localTile() && this.localTile().localTileStarted
  }

  setSession(meeting, attendee){
    let t = this;
    return new Promise((resolve, reject) => {
      const configuration = new MeetingSessionConfiguration(meeting, attendee);
      t.meetingSession = new DefaultMeetingSession(configuration, logger, t.deviceController);
      resolve('yes') ;
    })
  }

  startScreenShare(videoTile){
    return this.meetingSession.audioVideo.startContentShareFromScreenCapture()
  }

  fetchAudioOutput(){
    let devices = []
    let t = this;
    return new Promise((resolve, reject) => {
      this.deviceController.listAudioOutputDevices(true).then(function(v) {
        v.forEach(mediaDeviceInfo => {
          devices.push(mediaDeviceInfo) 
        })
        resolve(devices)
      })
    })
  }

  fetchAudioInput(){
    let devices = []
    let t = this;
    return new Promise((resolve, reject) => {
      this.deviceController.listAudioInputDevices(true).then(function(v) {
        v.forEach(mediaDeviceInfo => {
          devices.push(mediaDeviceInfo) 
        })
        resolve(devices); 
      })
    })
  }

  fetchVideoInput(){
    let devices = []
    let t = this;
    return new Promise((resolve, reject) => {
      this.deviceController.listVideoInputDevices(true).then(function(v) {
        v.forEach(mediaDeviceInfo => {
          devices.push(mediaDeviceInfo) 
        })
        resolve(devices); 
      })
    })
  }

  subscribeToRosterUpdate(callback) {
    this.rosterUpdateCallbacks.push(callback);
  }

  subscribeToLocalVideoUpdate(callback) {
    this.localVideoCallbacks.push(callback);
  }
   unsubscribeFromLocalVideoUpdate(callback){
    const index = this.localVideoCallbacks.indexOf(callback);
    if (index !== -1) {
      this.localVideoCallbacks.splice(index, 1);
    }
  };

  unsubscribeFromRosterUpdate(callback){
    const index = this.rosterUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      this.rosterUpdateCallbacks.splice(index, 1);
    }

  };

}



