import cameraStates from '../utils/camera_states.js'
export default class preRoomSession {
  constructor(context) {
    this.context = context 
    this.localVideoCallbacks = []
    this.toggleVideo = this.toggleVideo.bind(this)
    this.localVideoActive = this.localVideoActive
    this.publishLocalVideoUpdate = this.publishLocalVideoUpdate.bind(this)
    this.subscribeToLocalVideoUpdate = this.subscribeToLocalVideoUpdate.bind(this)
    this.unsubscribeFromLocalVideoUpdate =this.unsubscribeFromLocalVideoUpdate.bind(this)
    let t=  this
    this.meetingSession = {
      audioVideo: {
        realtimeIsLocalAudioMuted: () => {
          return !t.context.state.micEnabled;
        },
        realtimeMuteLocalAudio: t.context.stopMicPreview,
        realtimeUnmuteLocalAudio: t.context.micPreview,
      }
    }
  }

  localVideoActive() { return  this.context.videoActive()}

  toggleVideo(camera) { 
    return this.context.toggleVideo(camera)
  }

  publishLocalVideoUpdate()  { 
    let t = this;
    for (let i = 0; i < t.localVideoCallbacks.length; i += 1) {
      const callback = t.localVideoCallbacks[i];
      callback(t.localVideoActive() ? cameraStates.active : cameraStates.off);
    }
  } 

  subscribeToLocalVideoUpdate(callback) { this.localVideoCallbacks.push(callback); 
  } 
  unsubscribeFromLocalVideoUpdate (callback)  {   
    const index = this.localVideoCallbacks.indexOf(callback);
    if (index !== -1) { 
      this.localVideoCallbacks.splice(index, 1); 
    }
  }
}

