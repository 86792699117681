import React, { useState, useEffect } from "react";
import MicOff from "./mic_off";
import MicOn from "./mic_on";
import CameraOn from "./camera_on";
import CameraOff from "./camera_off";
import MicCameraToggling from '../shared/mic_camera_toggling.js'
import useMicStatus from './hooks/use_mic_status.js'
import micStates from './utils/mic_states.js'
import useCanUnmute from './hooks/use_can_unmute.js'
import useCameraStatus from './hooks/use_camera_status.js'
import cameraStates from './utils/camera_states.js' 
import RoomControls from './room_controls.js'; 

const PresenterControls = (props) => {
  let  micState =  useMicStatus() 
  const cameraState = useCameraStatus(props)
  const camera_toggle = cameraState === cameraStates.active 

  return MicCameraToggling(props, (args) => { 
   const {handleCameraClick, handleAudioClick} = args
  return (
    <>
      <h3 className="text-lg uppercase font-bold">Presenter Controls</h3>
      <div className="flex flex-row my-2 items-center">
        <button
          onClick={handleAudioClick}
          className={
            micState === micStates.active  
              ? "inline-block align-middle relative text-white h-11 w-11 rounded-full bg-green"
              : "inline-block align-middle relative text-white h-11 w-11 rounded-full bg-red-600"
          }
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
            {micState === micStates.active ? <MicOn /> : <MicOff />}
          </div>
        </button>
        {micState === micStates.active ? (
          <div className="bg-gray-light text-sm ml-2 w-1/2 rounded-md pl-2">
            Your Audio is Enabled
          </div>
        ) : (
          <div className="bg-gray-light text-sm ml-2 w-1/2 rounded-md pl-2">
            Your Audio is Muted
          </div>
        )}
      </div>
      <div className="flex flex-row my-2 items-center">
        <button
          onClick={handleCameraClick}
          className={
            camera_toggle
              ? "inline-block align-middle relative text-white h-11 w-11 rounded-full bg-green"
              : "inline-block align-middle relative text-white h-11 w-11 rounded-full bg-red-600"
          }
        >
          <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
            {camera_toggle ? <CameraOn /> : <CameraOff />}
          </div>
        </button>
        {camera_toggle ? (
          <div className="bg-gray-light text-sm ml-2 w-1/2 rounded-md pl-2">
            Your Camera is On
          </div>
        ) : (
          <div className="bg-gray-light text-sm ml-2 w-1/2 rounded-md pl-2">
            Your Camera is Off
          </div>
        )}
      </div>
     </>
   );
  })
};

export default PresenterControls;
