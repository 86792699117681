import React from 'react'; 
import Svgx from '../shared/svgx.js'
const background = "/Low-Poly-Background.jpg";

const InfoBar = (props) => {

  return (
    <div
      className="px-2 py-1 bg-cover bg-bottom flex justify-between items-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      {props.session_data && (
        <>
          <h2 className="px-1 lg:px-10 py-1 text-xl md:text-3xl text-white font-semibold">
            {props.session_data.room_name}
          </h2>
          <div className="lg:w-1/4 space-x-4 text-right mr-10">
            <span className="text-white text-xl font-bold">
              Room count: {props.attendees.length}
            </span>
            <a
              className="text-white cursor-pointer text-sm space-x"
              onClick={props.toggleRoster}
            >
              <span>View Attendance</span>
              <Svgx
                svgKey={"arrow-right"}
                options={{
                  className: "inline",
                  width: "20px",
                  height: "20px",
                }}
                size={"small"}
              />
            </a>
          </div>
        </>)}
    </div>
  )
}

export default InfoBar

