//inspired by https://mikerogers.io/2019/09/16/using-actioncable-and-stimulus-to-remotely-update-partials-in-rails-6
import consumer from '../../channels/consumer.js'
import { Controller } from 'stimulus'; 
import { debounce }  from 'lodash'
import Rails from '@rails/ujs'; 
export default class extends Controller {
  static targets = ['user']; 


  connect(){
    setTimeout(this.startSubscription.bind(this), 
      50
    )
    this.element[this.identifier] = this
  }

  closeOtherChats() {
    let chatIdentifier = 'event-management--attendee-chat'
    this.userTargets.forEach((node) => {
      node.querySelector(`[data-controller="${chatIdentifier}"]`)[chatIdentifier].close()
    }
    )
  }

  initialize(){
    this.refreshUserList = debounce(async (id)=> {  
    let t = this;  
    let userInScope = await this.checkIfUserInScope(id)
    if (!userInScope){
      return;
    }

    let scroll = document.scrollingElement.scrollTop; 
    let handleScroll = function(){ 
      window.scroll(0, [scroll]);
      document.removeEventListener('turbolinks:load', handleScroll);
    }
     document.addEventListener('turbolinks:load', handleScroll); 
     Turbolinks.visit(document.location) }, 5000)
   } 

  checkIfUserInScope(id){
    let t = this;
    return new Promise((resolve, reject) => {
     Rails.ajax({
       url: window.location,
       type: 'GET',
       success: (data) => {
         let resp =  t.findUser(id, data.body) 
         resolve(resp)
       }
     }
     )
    })
  }

  startSubscription(){
    let t = this;
    this.subscription =  consumer.subscriptions.create({channel: 'AttendeeManagementChannel', 
      event: t.data.get('event-id')}, { 
        received: (data) => {
          let id =  data.user_id
          let user =  t.findUser(id); 

          if (user && !data.message) {
            t.renderRow(user, t._parseHTMLResponse(data.html))
          }
          else {
            //refresh current query as new user has entered
            if (!data.message){
              this.refreshUserList(id)
            }

          }

          if (data.message){
            let  messaging_user = t.findUser(data.message.user.id);  
            if (messaging_user) {
              messaging_user
                .querySelector('[data-controller="event-management--attendee-chat"]')
                 ['event-management--attendee-chat'].countMessage();
            }
          }
        }
      }
    )
  }

  disconnect(){
    this.subscription.unsubscribe(); 
  }


  renderRow(user, newBody){
    let permanentNodes = user.querySelectorAll("[id][data-turbolinks-permanent]");
    permanentNodes.forEach(function(element){
      var oldElement = newBody.querySelector(`#${element.id}[data-turbolinks-permanent]`)
      oldElement.parentNode.replaceChild(element, oldElement);
    });


    while( user.firstChild ) { user.removeChild( user.firstChild ); }

    while( newBody.firstChild.firstChild ) { user.appendChild( newBody.firstChild.firstChild ); }
  }

  findUser(id, elem){
    let theElem = elem || this.element
    return theElem.querySelector(`tr[data-user-id='${id}']`) 
  }

  _parseHTMLResponse(html) {
   let parser = new DOMParser();
    let responseDocument = parser.parseFromString( `<template>${html}</template>` , 'text/html');
    let parsedHTML = responseDocument.head.firstElementChild.content;
    return parsedHTML;
  }
}
