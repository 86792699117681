export function messageReceived(context, data) {
  let currentMessages =[... context.state.messages]
  currentMessages.unshift(data.message)
  context.setState({messages: currentMessages}) 

}

export function sendMessage(message){
  this.consumer.perform('speak', {message: message })
}



