import React, { useState, useEffect } from "react";
import screenShareSupported from './utils/screen_share_supported.js'

const share_screen = "/share-screen.jpg";

const PresentationBox = (props) => {

  useEffect(() => {
    const obs = {
      contentShareDidStop: () => {
        setStreamShareIsMine(false)
      }
    }
    if (typeof props.sessionManager.meetingSession.audioVideo.addContentShareObserver === 'function') {
      props.sessionManager.meetingSession.audioVideo.addContentShareObserver(obs)
    }
    return () => {
     if (typeof props.sessionManager.meetingSession.audioVideo.removeContentShareObserver === 'function') {
      props.sessionManager.meetingSession.audioVideo.removeContentShareObserver(obs)
     }
    }
  }, [])

  const [contentDialog, setContentDialog] = useState(false);  
  const [shareCameraSelection, setShareCameraSelection] = useState('');
  const [streamShareIsMine, setStreamShareIsMine] = useState(false);

  const startScreenShare = async () => {
    if (canStartShare()) {
      await props.sessionManager.startScreenShare();
      setStreamShareIsMine(true);
    }
  };

  function canStartShare(){
    return (props.currentUser.role === "presenter" || props.nonPresenterShare) && !props.screenShareOn;
  }

  async function  startStreamShare()  {
    let stream = null
    try {
    stream = await navigator.mediaDevices.getUserMedia({video: {deviceId: shareCameraSelection}})
    props.sessionManager.meetingSession.audioVideo.startContentShare(stream); 
    }
    catch(e) {
      console.log(e); 
    }
    setContentDialog(false); 
    setStreamShareIsMine(true); 
  }

  function toggleFullscreen() {
    let elem = props.screenShareRef.current

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        alert(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      document.exitFullscreen();
    }
  }

  return (
    <div>
      <div
        className={
          props.screenShareOn
            ? "embed-responsive aspect-ratio-16/9 relative bg-black"
            : "embed-responsive aspect-ratio-16/9 relative bg-black"
        }
      >
        <video ref={props.screenShareRef}></video>
        {canStartShare() && (
          <div className="absolute w-full h-full">
            <div className="h-full flex justify-center align-center flex-wrap">
              {screenShareSupported() &&
              <button data-testid='jst-screen-share-start' onClick={startScreenShare} className="text-white w-full">
                Start Screen Share
              </button>
              }
              <button onClick={()=> setContentDialog(true)} className='text-white w-full'> 
                 Stream From Camera
              </button>
            </div>

          </div>
        )}
        {contentDialog && (
          <div className='absolute w-1/2 h-96 bg-white'> 
            <button className='absolute right-2' onClick={()=> setContentDialog(false)}>X</button>
            <p>Select a Camera To Stream From </p>
             <select
               name="VideoInput"
               className="bg-gray-100 text-gray-dark p-1 text-sm"
               onChange={(e) =>{  setShareCameraSelection(e.target.value)}} >
               <option value="">Select A Device</option>
                {props.videoDevices.map((d, i) => {
                  return (
                    <option value={d.deviceId} key={d.deviceId}>
                      {d.label}
                    </option>
                  );
                })}
              </select>
            {shareCameraSelection &&
            <button className='btn block mt-5'  onClick={startStreamShare} >Start Now</button>
            }
          </div>
        )
        }
        <button
          className="text-white absolute bottom-2 right-2 w-6 bg-gray-dark"
          onClick={toggleFullscreen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
            />
          </svg>
        </button>
        {streamShareIsMine &&
        <button data-testid='jst-stop-screen-share-btn' onClick={()=> {props.sessionManager.meetingSession.audioVideo.stopContentShare();
                               }
                          } 
                className='text-white w-48 absolute btn transform  bottom-2 left-1/2 -translate-x-1/2'> 
                Stop Sharing
        </button>
        }

      </div>
    </div>
  );
};

export default PresentationBox;
