import React from "react";

const MicOn = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="white"
    >
      <title>mic on</title>
      <g>
        <path
          className="cls-1"
          d="M126.89,84.37c0,6.35,0,12.7,0,19-.11,11.76-5.53,20.39-16.16,25.2-10,4.51-19.55,3-28-4-6.63-5.48-9.78-12.7-9.74-21.36q.1-19.51,0-39A27.22,27.22,0,0,1,94,37.78c14.35-3.36,28.83,5.81,32.22,20.43a25.78,25.78,0,0,1,.63,5.54C126.93,70.62,126.89,77.49,126.89,84.37Z"
        />
        <path
          className="cls-1"
          d="M93,158.2c0-1.87-.06-3.75,0-5.62.07-1.45-.27-2.15-1.91-2.48a48.88,48.88,0,0,1-11.75-4,46.33,46.33,0,0,1-19.84-18.37,45.18,45.18,0,0,1-6.51-24c0-2.08-.05-4.16,0-6.24a7.06,7.06,0,0,1,6.91-7.07c3.55-.25,6.82,3.15,7,7,.08,2.29,0,4.58,0,6.87,0,16.46,12.52,29.78,27.47,32.35,18.13,3.11,35.63-9.63,38-27.12a110.34,110.34,0,0,0,.58-11.8,7.14,7.14,0,0,1,3.74-6.39c4.54-2.69,9.91,1.14,10.13,5.53.51,9.88-.31,19.57-5.09,28.47-5.72,10.65-14.11,18.41-25.56,22.62a66.71,66.71,0,0,1-6.56,1.92c-2.72.7-2.74.66-2.75,3.4,0,3.54.07,7.08,0,10.62a7.19,7.19,0,0,1-5.61,7,6.92,6.92,0,0,1-8.23-6.1c-.15-2.18,0-4.37,0-6.56Z"
        />
      </g>
    </svg>
  );
};

export default MicOn;
