import React from "react";

const CameraOff = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="white"
    >
      <title>cam off</title>
      <g>
        <path
          className="cls-1"
          d="M56.09,129.33c-3.52,0-6.37-4-6.37-7.49V78.68c0-3.52,2.85-7.54,6.37-7.54h16.2a6.26,6.26,0,0,0,5.23-2.83l7.74-10.65a2.58,2.58,0,0,1,2.17-1.18h25.5a2.59,2.59,0,0,1,2.21,1.24l4.65,6.82,7.47-7.68-3.37-5.5a11.46,11.46,0,0,0-9.76-5.47H86.28a11.44,11.44,0,0,0-9.58,5.18l-5.58,8.52a3.29,3.29,0,0,1-2.77,1.5H57.82A18.13,18.13,0,0,0,39.7,79.22v41.46a18.12,18.12,0,0,0,9.86,16.13l7.27-7.48Z"
        />
        <path
          className="cls-1"
          d="M147.38,61.87l12.91-13.25a5.94,5.94,0,0,0,0-8.22,5.49,5.49,0,0,0-7.93,0L114.22,79.56a22.95,22.95,0,0,0-31.91,32.76l-41.89,43a6,6,0,0,0,0,8.21,5.47,5.47,0,0,0,7.93,0l24.1-24.74h69.73a18.12,18.12,0,0,0,18.12-18.12V79.22A18.12,18.12,0,0,0,147.38,61.87Zm2.9,60c0,3.52-2.85,7.49-6.37,7.49H81.67L91.83,118.9a23,23,0,0,0,29.21-30l17.32-17.78h5.55c3.52,0,6.37,4,6.37,7.54Z"
        />
      </g>
    </svg>
  );
};

export default CameraOff;
