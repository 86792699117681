import Rails from '@rails/ujs'; 
import { Controller } from "stimulus"; 
export default class extends Controller {
 static targets = ['form']
 
  connect(){
   if (this.data.get('readonly')){
     _.each(this.element.querySelectorAll('input,select,textarea'), function(v, i){
        v.setAttribute('disabled', true); 
     })
   }
  }

  update(e){
    e.preventDefault();
    Rails.fire(this.formTarget, 'submit'); 
  }

  reRenderSelf(e){

    if (e.detail[2]){
     var returnHtml = e.detail[2].response;
    }
    else {
    var returnHtml = e.detail[0].response;
    }
    let parent = this.element.parentNode;
    this.element.remove();
    parent.innerHTML = returnHtml;
  }

  appendSelf(e) {
    if (e.detail[2]){
     var returnHtml = e.detail[2].response;
    }
    else {
    var returnHtml = e.detail[0].response;

    }
   let parent = this.element.parentNode;
    this.element.remove();
    this.appendTarget.insertAdjacentHTML('beforeend', returnHTML); 

  }

  selectedRows(){
    let rows = [];
    this.rowCheckboxTargets.forEach(function(el){
      if (el.checked){
      rows.push(el.parentNode.parentNode);
      }
    })

    return rows;
  }

  toggleAllRows(e){

    let checked = e.target.checked;

    this.rowCheckboxTargets.forEach(function(el){
      el.checked = checked;
    })
  }
}

