import consumer from '../channels/consumer.js'
import { Controller } from 'stimulus'; 
export default class extends Controller {

  connect(){
    let t = this;
    setTimeout(this.subscribe.bind(t), 50 )
    this.update = this.update.bind(t); 
  }

  subscribe(){
    let  t = this;
    this.consumer =  consumer.subscriptions.create("AppearanceChannel", {
      connected() {
        this.install()
        t.update()
      },

      disconnected() {
        t.uninstall()
      },

      rejected() {
        t.uninstall()
      },

      install() {
        document.addEventListener("visibilitychange", t.update, false)
      }

    })
  }

  documentIsActive() {
    return document.visibilityState === "visible" 
  }

  appear() {
    this.consumer.perform("appear", { appearing_on: this.appearingOn() })

  }

  update() {
    this.documentIsActive() ? this.appear() : this.away()
  }

  away() {
    this.consumer.perform("away")
  }

  appearingOn() {
    return this.data.get('appearing-on')
  }

  uninstall(){
    document.removeEventListener("visibilitychange", this.update, false)
  }

  disconnect(){
     this.uninstall()

      if (this.consumer) {
        this.consumer.unsubscribe();
      }
   }
}


