import React from "react";
import VideoTile from "./video_tile.js";
export default class Presenter extends React.Component {
  render() {
    return (
      <div className="my-1 px-1 w-full">
        <article>
          <header className="flex flex-col my-5 border-2 border-gray-light rounded-lg shadow-md p-5">
            <div className="inline-block">
              <h3 className="font-bold inline-block mr-2">
                {this.props.presenter && this.props.presenter.user && (
                  <span>{this.props.presenter.user.name}</span>
                )}
              </h3>
              <p className="bg-apricot-light text-white font-semibold py-1 px-4 rounded-full text-xs text-center inline-block capitalize">
                presenter
              </p>
            </div>
            <h6 className="my-5 text-lg font-semibold">
              {this.props.presenter && (
                <span> {this.props.presenter.company.name}</span>
              )}
            </h6>
            {this.props.ceu_credits && (
              <p className="bg-gray-dark text-white font-semibold py-1 px-4 rounded-full text-xs text-center w-2/5 inline-block">
                <span>{this.props.ceu_credits}</span>
              </p>
            )}
            {this.props.ceu_credits && (
              <p className="my-5 text-sm break-words">
                {this.props.presenter && (
                  <span> {this.props.presenter.about_the_speaker}</span>
                )}
              </p>
            )}
            <p className="text-sm break-words">
              {this.props.presenter && !this.props.ceu_credits && (
                <span> {this.props.presenter.about_the_speaker}</span>
              )}
            </p>
          </header>
        </article>
      </div>
    );
  }
}
