import React from 'react'; 
import PresentationBox from '../presentation_box.js'; 
import Controls from '../controls.js'; 
import Attendees from '../attendees.js'; 
import ActiveSpeaker from '../active_speaker.js'; 

const MiddleColumn = (props) => {
 return (
     <div className="flex flex-col lg:w-1/2 my-5">
              {!props.loading && (
                <div>
                  <PresentationBox
                    sessionManager={props.sessionManager}
                    screenShareRef={props.screenShareRef}
                    screenShareOn={props.screenShareOn}
                    videoDevices={props.videoInputDevices}
                    currentUser={props.user}
                    nonPresenterShare={props.nonPresenterShare}
                  />
                  <div className='flex'>
                   <ActiveSpeaker />
                  { props.user.role !== "presenter" && (
                    <div className='w-1/3 text-center mt-2'> 
                    <Controls
                      sessionManager={props.sessionManager}
                      defaultCamera={props.VideoInput}
                      user={props.user} 
                      findUser={props.findUser}
                      canTurnCameraOn={props.canTurnCameraOn}
                    />
                   </div>
                  )}
                  </div>
                  <Attendees
                    wrapperClass={
                      props.user.role === "presenter" ? "mt-3" : ""
                    }
                    findUser={props.findUser}
                    />
                </div>
              )}
            </div>
   ) 
}

export default MiddleColumn
