import React from 'react'; 
import Rails from "@rails/ujs"

const ApiContext = React.createContext() 
class api  {
  constructor(urls) {
    this.sessionUrl = ''
    this.attendeeUrl = ''
    this.muteToggleCallbacks = []
    this.muteToggleOn = false
    Object.assign(this, urls)
  }
  
  fetchRoomSession(success, error)  {
    let  t = this;
    Rails.ajax({
      type: 'GET', 
      url: t.sessionUrl, 
      dataType: 'json',
      success: success, 
      error: error 
    }
    )
  }

 fetchAttendee(id, success, error){
   let  t = this;
    Rails.ajax({
      type: 'GET', 
      url: t.attendeeUrl + id, 
      dataType: 'json',
      success: success, 
      error: error 
    }
    )
 }

 subscribeToMuteToggleUpdates(callback) {
   this.muteToggleCallbacks.push(callback)
 }

 publishMuteToggleUpdate(status){
  let t = this
  for (let i = 0; i < t.muteToggleCallbacks.length; i += 1) {
      const callback = t.muteToggleCallbacks[i];
      callback(status);
    }
 }
 unsubscribeFromMuteToggleUpdates(callback){
    const index = this.muteToggleCallbacks.indexOf(callback);
    if (index !== -1) {
      this.muteToggleCallbacks.splice(index, 1);
    }
  };

 toggleRoomLock(data, success, error)  {
    let  t = this;
    Rails.ajax({
      type: 'PUT', 
      url: t.sessionUrl, 
      dataType: 'json',
      success: success, 
      error: error,
      beforeSend(xhr, options) {
       xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
       options.data = JSON.stringify(data)
       return true
      }
     }
    )
  }

}

export default  ApiContext 
export { api }



