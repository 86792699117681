import React from "react";
import ChatMessage from "./chat_message.js";
export default class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleMessageSend = this.handleMessageSend.bind(this);
    this.messageScrollEnd = React.createRef();
  }

  handleMessageSend() {
    if (this.state.message.trim() !== "") {
      this.props.sendMessage(this.state.message);
    }
    this.setState({ message: "" });
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.messages.length !== this.props.messages.length) {
      this.messageScrollEnd.current.scrollTop = 0;
    }
  }

  render() {
    let t = this;
    return (
      <div className="border-b-15 border-purple-light">
        <div className="w-full flex justify-between bg-gray-light">
          <input
            className="flex-grow m-4 py-2 px-4 mr-1 rounded-full border border-gray-300 resize-none"
            value={this.state.message}
            onKeyPress={(e) => (e.key == "Enter" ? t.handleMessageSend() : "")}
            onChange={this.handleChange}
            placeholder="Message..."
          />
          <button className="m-2" onClick={this.handleMessageSend}>
            <svg
              className="svg-inline--fa lg:hidden xl:block text-apricot-light fa-paper-plane fa-w-14 w-10 h-10 py-2 mr-2"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="paper-plane"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
              />
            </svg>
          </button>
        </div>
        <div
          className="flex flex-col w-full h-160 overflow-hidden overflow-y-auto text-gray bg-gray-light dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0"
          ref={this.messageScrollEnd}
        >
          <div className="my-5">
            {this.props.messages && this.props.messages.map((message) => {
              return (
                <ChatMessage
                  chatType={this.props.chatType}
                  fromCurrentUser={message.user.id === this.props.currentUser}
                  key={message.id}
                  message={message}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
