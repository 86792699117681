import { Controller } from "stimulus";

export default class extends Controller {
 
  connect() {
    document.removeEventListener('turbolinks:load', this.func)
  }

  bufferedSearch(e) {
    let t = this;
    let id = e.target.getAttribute('id')
    this.func = () => { 
    
    let elem =  document.querySelector('#' + id)
    elem.selectionStart = elem.selectionEnd = 10000; 
    elem.focus()
    }
    document.addEventListener('turbolinks:load', this.func) 
    clearTimeout(this.delay)
    this.delay = setTimeout(() => {
      t.submit(e)
    }, 1000)
  }

  

  submit(e) {
    e.preventDefault()
    let data = new FormData(e.target.form); 
    let query = new URLSearchParams(data).toString()
    let url = e.target.form.action  + '?' + query;
    Turbolinks.visit(url); 
  }
}
