const selectedClass =  ''
const nonSelectedClass = ''
import { Controller } from 'stimulus'; 
import Rails from '@rails/ujs'; 
export default class extends Controller {
  static targets = ['link']

  connect() {
    this.selected =  this.data.get('selectedClass')
    this.notSelected  = this.data.get('notSelectedClass')
    this.linkTargets.forEach(function(link) {
      delete link.dataset.method
    })
  }

  disconnect(){
  }

  create(e) {
    e.preventDefault()
    let t = this;
    Rails.ajax({
      type: 'post', 
      url: e.target.getAttribute('href'), 
      dataType: 'json', 
      success: function(resp){
        t.linkTargets.forEach(function(link) {
          if (link == e.target){
            link.classList.remove(t.notSelected); 
            link.classList.add(t.selected); 
          }
          else {
            link.classList.remove(t.selected); 
            link.classList.add(t.notSelected); 
          }
        })
      }, 
      failure: function(resp) {
        alert(resp.message);

      }
    })

  }

}


