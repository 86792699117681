import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['audio']
  
  connect() {
   }

  disconnect(){
    }

  play(){
    let t = this;
    let newSrc= 'https://www.realmofdarkness.net/audio/arnold/main/daddy.mp3'
    this.audioTarget.querySelector('source').setAttribute('src', newSrc); 
    this.audioTarget.load()
    this.audioTarget.play()

  }

  no(){
    let newSrc= 'https://www.realmofdarkness.net/audio/arnold/main/fu.mp3'
    this.audioTarget.querySelector('source').setAttribute('src', newSrc); 
    this.audioTarget.load()
    this.audioTarget.play()

  }
}
