import React from "react";
import ReactDOM from "react-dom";
import EventRoomMain from "../../event_rooms/index.js";
import PreRoomSetup from "../../event_rooms/pre_room_setup.js";
import { Controller } from "stimulus";
import Roster from "../../event_rooms/roster.js";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ApiContext from "../../event_rooms/api_context.js";
import { api } from "../../event_rooms/api_context.js";
import "../../../assets/stylesheets/fonts.css";

export default class extends Controller {
  static targets = [];
  static values = {
    isPresenter: Boolean
  }    

  connect() {
    const revision = this.data.get("release").toString();
    window.addEventListener('turbolinks:before-visit', this.userConfirm); 
    Sentry.init({
      dsn:
        "https://09bf65a366d74c95a85213a846d63cd2@o174846.ingest.sentry.io/5731791",
      integrations: [new Integrations.BrowserTracing()],
      whitelistUrls: ["events.lighting.exchange"],
      release: "frontend-fails-events-lighting-exchange@" + revision,
      tracesSampleRate: 1.0,
    });
    let t = this;
    const Api = new api({ sessionUrl: t.data.get("sessionUrl"), attendeeUrl: t.data.get('attendeeUrl') });
    if (!this.isPreview) {
      ReactDOM.render(
        <ApiContext.Provider value={Api}>
          <PreRoomSetup isPresenter={t.isPresenterValue}>
            {(devices) => {
              return (
                <Roster
                  room_id={this.data.get("room")}
                  mainFloorUrl={t.data.get("mainFloorUrl")}
                >
                  {(state, messageSender) => (
                    <EventRoomMain
                      sendMessage={messageSender}
                      {...state}
                      {...devices}
                      mainFloorUrl={t.data.get("mainFloorUrl")}
                      nonPresenterShare={t.data.get("nonPresenterShare") === "true"? true : false}
                    />
                  )}
                </Roster>
              );
            }}
          </PreRoomSetup>
        </ApiContext.Provider>,
        this.element
      );
    }
  }

  userConfirm(e) {
    var result = confirm("Are you sure you want to leave the room?");
    if (!result) {
      e.preventDefault();
    }
  }


  disconnect() {
    window.removeEventListener('turbolinks:before-visit', this.userConfirm); 

    try {
      ReactDOM.unmountComponentAtNode(this.element);
    } catch (error) {}
  }

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbolinks-preview");
  }
}
