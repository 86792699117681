import React, {useContext, useState} from "react";
import SessionManagerContext from './session_manager_context.js'; 
import { DefaultActiveSpeakerPolicy } from 'amazon-chime-sdk-js'; 
import Svgx from "../shared/svgx.js";

const  ActiveSpeaker  = (props) => {
const [activeSpeaker, setActiveSpeaker] = useState(""); 
const chime = useContext(SessionManagerContext);
   React.useEffect(() => {
      const callback = (attendeeIds) => {
        if (attendeeIds.length) {
          if (chime.sessionManager?.roster() && chime.sessionManager?.roster()[attendeeIds[0]]){
            setActiveSpeaker({...chime.sessionManager?.roster()[attendeeIds[0]], ...{attendeeId: attendeeIds[0]}})
          }
        }
        else {
         setActiveSpeaker("") 
        }
      }
     if (chime.sessionManager) {
        chime.sessionManager.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
          new DefaultActiveSpeakerPolicy(0.2), 
          callback  
        )
     }
    }, [])


     const indicator =   (
      <Svgx
        svgKey={"volume-up"}
        options={{ className: "inline w-4 mr-3" }}
        size={"small"}
      />
    );
    return (
      
      <div className='w-1/3 h-4'>
        {activeSpeaker && 
        <>
         {indicator}
          <div className='inline text-sm'><strong className='text-gray-dark'>Speaking: </strong> {activeSpeaker.name}</div>
        </>
        }
      </div>
        
    );
}

export default ActiveSpeaker; 
