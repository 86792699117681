import {useState, useEffect} from 'react'
import cameraStates from '../utils/camera_states.js'

export default function useCameraStatus(props) {
  let initial = props.sessionManager?.localVideoActive() ? cameraStates.active : cameraStates.off
  const [cameraState, setCameraState] = useState(initial)
  useEffect(() => {
    if (typeof props.sessionManager?.subscribeToLocalVideoUpdate !== 'undefined') {
    const callback = (active) => {
     setCameraState(active);
    }
    props.sessionManager.subscribeToLocalVideoUpdate(callback)
    return () => {
      props.sessionManager.unsubscribeFromLocalVideoUpdate(callback); 
     }
    }
    else {
      setCameraState(props.sessionManager?.localVideoActive() ? cameraStates.active : cameraStates.off)
    }
   }, []);

   return cameraState
}
