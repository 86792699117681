import React from "react";
export default class ReceptionInfo extends React.Component {
  render() {
    return (
      <div className="flex flex-col">
        <div className="px-2 py-1 bg-gradient-to-r from-apricot-dark to-apricot-light mb-3">
          <h2 className="px-1 py-1 text-lg text-white font-semibold uppercase">
            What to Expect
          </h2>
        </div>

        {!!this.props.featured_speakers && this.props.featured_speakers > 0 && (
          <div className="flex flex-row rounded-md border border-gray-100 shadow-sm items-center align-center my-2">
            <div className="flex align-center items-center w-1/4 bg-gray-dark h-full rounded-l-md text-white pl-7 pt-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 40 40"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                />
              </svg>
            </div>
            <div className="w-3/4 p-5">
              <h3 className="font-bold text-lg uppercase ">
                {this.props.featured_speakers} Featured Speaker(s)
              </h3>
              <p>
                Value driven sessions with insightful industry thought leaders.
              </p>
            </div>
          </div>
        )}
        {!!this.props.exhibitors && this.props.exhibitors > 0 && (
          <div className="flex flex-row rounded-md border border-gray-100 shadow-sm items-center align-center my-2">
            <div className="flex align-center items-center w-1/4 bg-gray-dark h-full rounded-l-md text-white pl-7 pt-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 40 40"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </div>
            <div className="w-3/4 p-5">
              <h3 className="font-bold text-lg uppercase">
                {this.props.exhibitors} Exhibitor(s)
              </h3>
              <p>
                Connect with multiple manufacturers from the comfort of your
                desk (or sofa).
              </p>
            </div>
          </div>
        )}

        {!!this.props.ceus && this.props.ceus > 0 && (
          <div className="flex flex-row rounded-md border border-gray-100 shadow-sm items-center align-center my-2">
            <div className="flex align-center items-center w-1/4 bg-gray-dark h-full rounded-l-md text-white pl-7 pt-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </div>
            <div className="w-3/4 p-5">
              <h3 className="font-bold text-lg uppercase ">
                {this.props.ceus} CEU(s)
              </h3>
              <p>
                Earn AIA or CEU credits by engaging in captivating
                presentations.
              </p>
            </div>
          </div>
        )}

        {!!this.props.incentives && this.props.incentives > 0 && (
          <div className="flex flex-row rounded-md border border-gray-100 shadow-sm items-center align-center my-2">
            <div className="flex align-center items-center w-1/4 bg-gray-dark h-full rounded-l-md text-white pl-7 pt-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 40 40"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                />
              </svg>
            </div>
            <div className="w-3/4 p-5">
              <h3 className="font-bold text-lg uppercase ">
                {this.props.incentives} Incentive(s)
              </h3>
              <p>
                Receive amazing prizes that are way better than traditional
                tradeshow swag.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
