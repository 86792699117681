import React, { useState, useEffect, useContext } from "react";
import PresenterToggleControlsContext from './presenter_toggle_controls_context.js'; 
import useCanUnmute from  './hooks/use_can_unmute.js'

const  MuteAllToggle = (props) =>  {
  const context  = useContext(PresenterToggleControlsContext) 
  const canUnmute =  useCanUnmute(!context.muteToggleOn, context.user)
  const handleClick = () => {
  let data = {room: {mute_toggle_on: !!canUnmute}}
    context.toggleRoomLock(data, () => {
    },
      (error) => {
      try  {
        alert(error.errors.join(','))
      }
      catch {
        alert('There was a problem locking this room')
      }
     }
    )

  }

  const muteToggleOn = !canUnmute

    return (
      <div className="absolute">
        <div className="flex flex-row items-center">
          <button onClick={handleClick} className="flex flex-row">
            {muteToggleOn ? (
              <div className="flex flex-row">
                <div className="bg-white border border-gray-light font-bold py-2 px-5 text-center rounded-l-2xl text-xs">
                  OFF
                </div>
                <div className="bg-gray-dark border border-gray-light text-white font-bold py-2 px-5 text-center rounded-r-2xl text-xs">
                  ON
                </div>
              </div>
            ) : (
              <div className="flex flex-row">
                <div className="bg-gray-dark border border-gray-light font-bold py-2 px-5 text-white text-center rounded-l-2xl text-xs">
                  OFF
                </div>
                <div className="bg-white border border-gray-light font-bold py-2 px-5 text-center rounded-r-2xl text-xs">
                  ON
                </div>
              </div>
            )}
          </button>
          <div className="ml-2 text-sm">Mute All Attendees</div>
        </div>
        <div className="text-xs mt-2">
          {muteToggleOn ? "All attendees have been muted." : ""}
        </div>
      </div>
    );
}
export default MuteAllToggle
