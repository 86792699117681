import React from 'react'; 

const Avatar = (props) => {
   const initials = (name) => {
     let base = name.split(/\s+/)
     let int = ''
     let arr = base.map((d, i) => d && d[0] )
     if (arr.length > 2) {
      arr = [arr[0], arr[arr.length - 1]]
     }
     int =   arr.join(''); 
     return int.toUpperCase()
   }

   return (
     <div className={`inline-block align-middle relative text-white rounded-full ${props.sizingClasses}` }
       style={{backgroundColor: props.avatarColor }}> 
       <span className={`absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2
           ${props.textSize}
            `}> 
          {props.name &&
           initials(props.name)
          }
       </span>
     </div>
 ); 

}
export default Avatar; 
