import React from 'react' ;
const AttendeeTileLimit = 9;
const AgencyTileLimit = 4;
const PresenterTileLimit = 2;
export default class TileManager {
  constructor() { 
    this.agentVideoRefs = Array(AgencyTileLimit)
      .fill()
      .map(() => ({ tile: React.createRef(), user_id: ""}));
    this.attendeeVideoRefs = Array(AttendeeTileLimit)
      .fill()
      .map(() => ({ tile: React.createRef(), user_id: "" }));
    this.presenterVideoRefs = Array(PresenterTileLimit).fill().map(() => ({ tile: React.createRef(), 
      user_id: ""}));
    this.indexMap = {}
    this.tileIdToUserMap = {}
    this.screenShare =  null;
    this.tileAvailableForUser = this.tileAvailableForUser.bind(this); 
  }

  addGroups(...args){
    let t = this;
    args.forEach((current) => {
      t.indexMap[current] = {}
    })
    return this.indexMap
  }

  tileRefsForUser(user) { 
    if (user) {
      switch (user.role) {
        case "presenter":
          return this.presenterVideoRefs;
          break;
        case "agent":
          return this.agentVideoRefs;
          break;
        default:
          return this.attendeeTiles();
      }
    }
  }

  tileAvailableForUser(user) {
    return user && (this.tileRefsForUser(user).filter(tile => (tile.user_id === user.id)).length > 0)
  }

  attendeeTiles() {
    return this.attendeeVideoRefs
  }

  supportTiles() {
    return this.agentVideoRefs
  }

  presenterTiles() {
    return this.presenterVideoRefs
  }

  connectedPresenters() {
    return this.presenterVideoRefs.filter((el) => el.user_id !== '').length
  }

  acquireVideoElement(tileState, user)  {
    if (!user) return;
    const tileId = tileState.boundExternalUserId
    const collection = this.tileRefsForUser(user); 
    for (let i = 0; i < collection.length;   i += 1) {
      if (this.indexMap[user.role][i] === tileId) {
       
        return  collection[i] 
      }
    }
    // Return the next available video element.
    for (let i = 0; i < collection.length; i += 1) {
      if (!this.indexMap[user.role].hasOwnProperty(i)) {
        let isBound =  tileId === collection[i].user_id
        if (isBound) {
         this.indexMap[user.role][i] = tileId;
          return collection[i] 
        }
      }
    }
  }

 myTile(user) {
   return this.tileRefsForUser(user).find((t) => t.user_id === user.id)
 }
  releaseVideoElement(tileId) { 
    let t = this; 
    Object.keys(this.indexMap).forEach((groupKey, j) => {
      for (let i = 0; i < Object.keys(this.indexMap[groupKey]).length; i += 1) {
        if (this.indexMap[groupKey][i] === tileId) {
          delete this.indexMap[groupKey][i];
          this[groupKey + 'VideoRefs'].forEach((u) => {
            if (u.user_id === tileId) {
              u.user_id = null;
              u.attendeeId = null
            }
          })
        }
      }
    })
  }
  bindTilelessUsers(attendees){
    let  t =  this;
    const binds = attendees.filter((u) => !t.tileAvailableForUser(u))  
    this.bindUsers(binds)
  }

  bindUsers(attendees) {
    let  t = this;

    attendees.forEach((user) => {
      t.tileRefsForUser(user).some((tile, index) => {
        let isBound = tile.user_id === user.id;
        if (!tile.user_id || isBound) {
          tile.user_id = user.id;
          tile.attendeeId = user.attendeeId
          tile.join_time = user.join_time
          if (t.indexMap[user.role]) {
           t.indexMap[user.role][index] = user.id 
          }
          return true;
        }
      });
    });
  }
}
