import { Controller } from "stimulus";

export default class extends Controller {
  static values = { delay: Number }

  connect(){
    let t = this;
    setTimeout(( ) => (t.element.closest('[data-controller="toggler"]')
                                .toggler
                                .toggle(new Event(''))), t.delayValue)

  }

}
