import React from 'react'; 
import Rails from "@rails/ujs"

const PresenterToggleControlsContext = React.createContext({
 muteToggleOn: false, 
 roomLockOn: false, 
 toggleMute: () => {},
 toggleRoomLock: () => {}

}
) 

export default PresenterToggleControlsContext 




