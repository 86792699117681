import React, { useState, useEffect, useContext } from "react";
import MicOff from "./mic_off";
import MicOn from "./mic_on";
import CameraOn from "./camera_on";
import CameraOff from "./camera_off";
import MicCameraToggling from '../shared/mic_camera_toggling'
import useMicStatus from './hooks/use_mic_status.js'
import micStates from './utils/mic_states.js'
import useCanUnmute from './hooks/use_can_unmute.js'
import ApiContext from './api_context.js'
import useCameraStatus from './hooks/use_camera_status.js'
import cameraStates from './utils/camera_states.js' 


const Controls = (props) => {
  const api = useContext(ApiContext)
  let  micState =  useMicStatus() 
  micState = useCanUnmute(!api.muteToggleOn, props.user) ? micState : micStates.cannotUnmute
  const cameraState = useCameraStatus(props)
  const camera_toggle = cameraState == cameraStates.active
  const canTurnCameraOn  = cameraState !== cameraStates.disabled && props.defaultCamera
  
  return MicCameraToggling(props, (args) => { 
   const {handleCameraClick, handleAudioClick} = args
   const cameraMessage = !props.defaultCamera ? 'No camera detected' : 'Video transmitting currently at capacity'

    return (
    <>
      <div className='relative inline-block'> 
      <button
        onClick={handleAudioClick}
        disabled={micState === micStates.cannotUnmute}
        className={
          (micState === micStates.active)
            ? "btn-on"
            : "btn-off"
        }
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
          {micState === micStates.active ? <MicOn /> : <MicOff />}
        </div>
      </button>
      {micState == micStates.cannotUnmute && 

       <div className='text-xxs absolute p-2 rounded left-1/2 top-12 transform  -translate-x-1/2 hidden z-50 disabled-hover-message text-white bg-gray-dark w-32'>Presenter has muted all mics in the room.
        <div className='border-l-transparent border-r-transparent absolute -top-1 
         left-1/2 transform -translate-y-1/2 -translate-x-1/2  border-r-8 border-l-8 border-gray-dark  border-b-8'></div>
          </div>
      }
     </div>
      <div className='relative inline-block'>
      <button
        onClick={handleCameraClick}
        disabled={!canTurnCameraOn}
        className={
          (camera_toggle && canTurnCameraOn)
            ? "btn-on"
            : "btn-off"
        }>
        <div className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 w-full">
          {camera_toggle && canTurnCameraOn ? <CameraOn /> : <CameraOff />}
        </div>
      </button>
    {!canTurnCameraOn && 
       <div className='text-xxs absolute p-2 rounded left-1/2 top-12 transform  -translate-x-1/2 hidden z-50 disabled-hover-message text-white bg-gray-dark w-32'>
        <div className='border-l-transparent border-r-transparent absolute -top-1 
         left-1/2 transform -translate-y-1/2 -translate-x-1/2  border-r-8 border-l-8 border-gray-dark  border-b-8'></div>
           {cameraMessage}
         </div>
      }
    </div>
    </>
  );
  })
};

export default Controls;
