const DeviceObserver = (t) => {
      return {
        audioOutputsChanged: (v) => {
        let ids = v.map((d, i) => d.deviceId )
        let AudioOutput = {}
        if (!t.state.AudioOutput || !ids.includes(t.state.AudioOutput.deviceId)) {
          AudioOutput = {AudioOutput: v[0]}
        }
        if (AudioOutput['AudioOutput']){
          t.sessionManager.setDeviceById(v[0].deviceId, 'AudioOutput').then(() => {
            t.setState({audioOutputDevices: v, ...AudioOutput})
          })
        }
        else {
        t.setState({audioOutputDevices: v, ...AudioOutput})
        }
      }
      }
 }

export default DeviceObserver
