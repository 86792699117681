import React from "react";

const CameraOn = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      fill="white"
      className="pb-0.5"
    >
      <title>cam on</title>
      <g>
        <path
          className="cls-1"
          d="M142.18,146.45H57.82A18.12,18.12,0,0,1,39.7,128.33V86.87A18.12,18.12,0,0,1,57.82,68.75H68.35a3.32,3.32,0,0,0,2.77-1.5l5.58-8.53a11.43,11.43,0,0,1,9.58-5.17h27.85A11.45,11.45,0,0,1,123.89,59l5,8.16a3.31,3.31,0,0,0,2.83,1.58h10.46A18.12,18.12,0,0,1,160.3,86.87v41.46A18.12,18.12,0,0,1,142.18,146.45ZM56.09,78.79c-3.52,0-6.37,4-6.37,7.54v43.16c0,3.52,2.85,7.49,6.37,7.49h87.82c3.52,0,6.37-4,6.37-7.49V86.33c0-3.52-2.85-7.54-6.37-7.54H127.59a6.25,6.25,0,0,1-5.33-3l-7.12-10.44a2.59,2.59,0,0,0-2.21-1.24H87.43a2.6,2.6,0,0,0-2.17,1.18L77.52,76a6.23,6.23,0,0,1-5.23,2.83Z"
        />
        <path
          className="cls-1"
          d="M100,127.93a23,23,0,1,1,23-23A23,23,0,0,1,100,127.93Z"
        />
      </g>
    </svg>
  );
};

export default CameraOn;
