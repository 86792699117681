import React, {useContext, useState} from 'react'; 
import SessionManagerContext from './session_manager_context.js'; 

const Status = (props) => {
  const chime = useContext(SessionManagerContext);
  const [message, setMessage] = useState('')
  React.useEffect(() => {
    const observer = {
      connectionDidSuggestStopVideo: () => {
        setMessage('Your network connection is poor')
      }, 
      videoSendBandwidthDidChange: (kbps, packetsPerSecond) => {
         //other health metrics that can be monitored..for now no ops
      },
      videoReceiveBandwidthDidChange: (newKbps, oldKbps) => {
      },
      videoSendHealthDidChange: (newKbps, oldKbps) => {
      },

     connectionDidBecomePoor: () => {
        setMessage('Your network connection is poor')
      },
      connectionDidBecomeGood: () => {
        setMessage('')
      }, 
    }
    chime.sessionManager.meetingSession.audioVideo.addObserver(observer); 

   },[])
 return (
   <div className={props.wrapperClass} style={{display: `${message ? 'block' : 'none'}`}}>   
     {message &&
      <p className='jst-status-message ml-3'>{message}</p> 
     }
   </div>
 ); 

}
export default Status; 
