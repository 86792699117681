import React from 'react'; 
import AgencySupport from '../agency_support.js'; 
import Chat from '../chat.js'; 
const RightColumn = (props) => {
 return (
   <div className="flex flex-col  lg:w-1/4 my-5 lg:ml-5">
 
              {!props.loading && (
                <div>
                  <AgencySupport
                    findUser={props.findUser}
                  />
                   <div className="px-2 py-1 bg-gradient-to-r from-apricot-dark to-apricot-light">
                    <h2 className="px-1 py-1 text-lg text-white font-semibold">
                      GROUP CONVERSATION
                    </h2>
                  </div>
                  <Chat
                    attendees={props.attendees}
                    sendMessage={props.sendMessage}
                    findUser={props.findUser}
                    messages={props.messages}
                  />
                </div>
              )}
            </div>
   ) 
}

export default RightColumn
