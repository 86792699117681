import { MeetingSessionStatusCode } from 'amazon-chime-sdk-js'; 
import { debounce } from 'lodash'; 
import cameraStates from './utils/camera_states.js'
const SessionObserver = (context) =>  {
  const videoUpdate =  debounce( (data)=>  {
     let running = false
    //could simplify this..but basically if in the process of turning on the
    //camera for someone who has video preference toggled on  and video becomes available..do not inadverently run the other coondition
     if (context.props.VideoInput && context.sessionManager.videoTogglePref  && data.canStartLocalVideo && !context.sessionManager.localVideoActive()){
       running = true
       context.sessionManager.startVideo(context.props.VideoInput).then(()=>{
         context.sessionManager.publishLocalVideoUpdate()
       })
      }
      if ( (!context.sessionManager.localVideoActive() || !context.sessionManager.videoTogglePref) && !running) {
        //do not send update if camera is already on since this message gets sent to all clients other than 
        //the one that triggered the event
        console.log('camera status: ', context.sessionManager.localVideoActive())
        context.sessionManager.publishLocalVideoUpdate(data.canStartLocalVideo ? cameraStates.off : cameraStates.disabled)
      }
    }, 1500)

  return {
    audioVideoDidStart:  () => { 
      if (context.props.user.role !== 'presenter') {
        context.sessionManager.meetingSession.audioVideo.realtimeMuteLocalAudio()
      }
      context.setState({ready: true}) 
    },

    videoTileDidUpdate: tileState =>{
      if (!tileState.boundAttendeeId ) {
        return
      }

      if (tileState.isContent ) {
        if (context.screenShareRef.current) {
          context.sessionManager.bindVideo(tileState.tileId, context.screenShareRef.current);  
          if (tileState.boundExternalUserId !== context.props.user.id) {
           context.setState({screenShareOn: true}) 
          }
          context.tileManager.screenShare = tileState;
        }
        return ;
      }

      let user  = context.findUser(tileState.boundExternalUserId);
      let boundTile = context.tileManager.acquireVideoElement(tileState, user)
      const rosterCallback =  (active) => {
        let user  = context.findUser(tileState.boundExternalUserId);
        let boundTile = context.tileManager.acquireVideoElement(tileState, user)
        if (boundTile && boundTile.tile.current) {
        context.sessionManager.bindVideo(tileState.tileId, boundTile.tile.current) 
        let publishUpdate = false
        if (context.sessionManager.roster()[tileState.boundAttendeeId]) {
           if (context.sessionManager.roster()[tileState.boundAttendeeId].video_active !== tileState.active ){ 
             publishUpdate = true
           }
           context.sessionManager.roster()[tileState.boundAttendeeId].video_active = tileState.active  || tileState.localTile && context.sessionManager.localVideoActive()
           context.sessionManager.roster()[tileState.boundAttendeeId].tileId = tileState.tileId
        }


        context.sessionManager.unsubscribeFromRosterUpdate(rosterCallback); 
        if (publishUpdate) {
         context.sessionManager.publishRosterUpdate()
         }
        }
      }
      if (user && boundTile) { 
       rosterCallback()
      }
      else {

       context.sessionManager.subscribeToRosterUpdate(rosterCallback); 
       }
    },
    videoTileWasRemoved: tileId => {
      Object.keys(context.sessionManager.tileManager.tileIdToUserMap).forEach((v, i) => {
        if (context.sessionManager.roster()[v] ) {
          if (context.sessionManager.roster()[v].tileId === tileId) {
            context.sessionManager.roster()[v].video_active = false
            context.sessionManager.publishRosterUpdate() 
          }
        }
      })

      if (context.tileManager.screenShare &&  tileId === context.tileManager.screenShare.tileId 
        && context.tileManager.screenShare.isContent){ 
        if (context.tileManager.screenShare.boundExternalUserId !== context.props.user.id) {
          context.setState({screenShareOn: false})
        }
        context.tileManager.screenShare = null;
      }
    },

    contentShareDidStart: () => {
      context.setState({screenShareOn: true})
    },
    contentShareDidStop: () => {
      context.setState({screenShareOn: false})
    },

    videoAvailabilityDidChange: (data) => {
      videoUpdate.cancel()
      videoUpdate(data); 
    },

    audioVideoDidStop: (sessionStatus) => {
    const sessionStatusCode = sessionStatus.statusCode();

    if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
      alert('This meeting has ended, redirecting to main floor...')
      setTimeout(() => {window.location = context.props.mainFloorUrl}, 2000)
    }

    else {
      alert(`You have been disconnected from the meeting. \r\nReason: ${MeetingSessionStatusCode[sessionStatusCode]}.\r\nYou may refresh this page to attempt re-connect.`)
    }
   }
  }
}

export default SessionObserver
